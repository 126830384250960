import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { listScipProducts } from "../../../../../redux/actions/scipAction";
import { useAppDispatch } from "../../../../../redux/hooks";
import ErrorBoundary from "../../../../../utils/errorBoundary";
import { Button, message, Space, Table, Typography } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";
import TableExpandScipComponent from "../../../../../components/modals/SCIP/TableExpandScipComponents";
import { EditOutlined } from "@ant-design/icons";
import PreviewScipModal from "../../../../../components/modals/SCIP/PreviewScip";
import { ProductRefContext } from "../../index";

const { Title, Text } = Typography;
export interface ProductHandle {
  focusInput: () => void;
}

const ProductList = forwardRef<ProductHandle>((props) => {
  const { ref } = useContext(ProductRefContext);
  const { setProducts } = useContext(ProductRefContext);

  const inputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => ({
    focusInput: () => {
      if (inputRef.current) {
        inputRef.current.focus();
        fetchData();
        onChildClick();
      }
    },
  }));

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") || "";

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [scipProducts, setScipProducts] = useState<[]>([]);
  const [scipProductCount, setScipProductCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<any>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

  const onSelectProducts = (
    newSelectedRowKeys: React.Key[],
    selectedRows: any[]
  ) => {
    if (newSelectedRowKeys.length <= 5) {
      setSelectedProducts(selectedRows);
      setProducts(
        selectedRows.map(({ DossierUuid, name }) => ({ DossierUuid, name }))
      );
    } else {
      message.error("You can only select up to 5 rows!");
    }
  };

  const productSelection: TableRowSelection<any[]> = {
    selectedRowKeys: selectedProducts.map((e) => e.productId),
    onChange: onSelectProducts,
    getCheckboxProps: (record: any) => ({
      disabled: !record.DossierUuid,
      // _id: record._id,
    }),
    preserveSelectedRowKeys: true,
  };

  const handleCreateScip = (record: string) => {
    navigate(`${record}`);
  };

  const onChildClick = () => {
    setSelectedProducts([]);
    setProducts([]);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (_, record) => (
        <Space>
          <Button
            type="text"
            onClick={() => handleCreateScip(record?.productId)}
            title="Edit Article"
            icon={<EditOutlined />}
          ></Button>
          {record?.DossierUuid && (
            <PreviewScipModal formData={record} type="products" />
          )}
        </Space>
      ),
    },

    {
      title: "Product",
      dataIndex: "number",
      key: "number",
      //sorter: true,
      width: 150,
      render: (_, record: any) => (
        <Text>
          {record.number}
          <br></br>
          <small>{record.name}</small>
        </Text>
      ),
    },
    // {
    //   title: "Total Components",
    //   dataIndex: "totalComponents",
    //   key: "totalComponents",
    //   //sorter: true,
    //   width: 150,
    // },
    {
      title: "Production  in EU",
      dataIndex: "EUProductionFlag",
      key: "EUProductionFlag",
      //sorter: true,
      width: 150,
      render: (value: any) => <Text>{value ? value?.text : ""}</Text>,
    },
    {
      title: "Submission Status",
      dataIndex: "Status",
      key: "Status",
      //sorter: true,
      width: 150,
      render: (text: any) => (
        <Text type={text === "Partially Completed" ? "warning" : "success"}>
          {text}
        </Text>
      ),
    },
    {
      title: "SCIP # Number",
      dataIndex: "ScipUuid",
      key: "ScipUuid",
      //sorter: true,
      width: 150,
      render: (text: any, record: any) => (
        <Text>
          {record.PrimaryArticleIdentifierType?.code === "66297"
            ? record.PrimaryArticleIdentifierValue
            : text}
        </Text>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>
          {text ? new Date(text).toLocaleString() : ""}
          <br />
          {record?.createdBy}
        </Text>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  const fetchData = () => {
    setLoading(true);
    dispatch(
      listScipProducts({
        pageSize: search && search?.length > 0 ? 10 : tablePagination.pageSize,
        pageNum: search && search?.length > 0 ? 1 : tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        searchField: search || "",
      })
    )
      .then((response: any) => {
        setScipProducts(response.scipProducts);
        setScipProductCount(response?.scipProductCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<any>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<any>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setScipProducts([]);
    }
  };

  return (
    <div ref={inputRef}>
      <ErrorBoundary>
        <Table
          loading={loading}
          dataSource={scipProducts}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="productId"
          pagination={{
            ...tablePagination,
            total: scipProductCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
          expandable={{
            expandedRowRender: (record: any) => (
              <TableExpandScipComponent productId={record.productId} />
            ),
          }}
          rowSelection={productSelection}
        />
      </ErrorBoundary>
    </div>
  );
});

ProductList.displayName = "Products";

export default ProductList;
