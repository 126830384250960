import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  FilePdfOutlined,
  FilterOutlined,
  PlusOutlined,
  EyeOutlined,
  EditOutlined,
  CalendarOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  Space,
  Typography,
  Button,
  Table,
  Input,
  Tag,
  Popconfirm,
  DatePicker,
  Alert,
  message,
  Tooltip,
} from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Campaign } from "../../../../utils/types/CampaignManager/campaignManager";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  createCampaignManager,
  listCampaigns,
} from "../../../../redux/actions/CampaignManager/campaignManagerAction";
import { getLocalStorage } from "../../../../utils/localStore";
import dayjs, { Dayjs } from "dayjs";
import DeleteCampaign from "../../../../components/modals/DeleteCampaignModal";
import ErrorBoundary from "../../../../utils/errorBoundary";
import { getRegulationsFromLocalStorage } from "../../../../utils/localStore";

const { Search } = Input;
const { Text } = Typography;

const CampaignManagerList: React.FC = () => {
  const dispatch = useAppDispatch();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [campaignsCount, setCampaignsCount] = useState<number>(0);

  const navigate = useNavigate();
  const [endDate, setEndDate] = useState<any>();

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Campaign>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [search, setSearch] = useState<any>();
  const pollingRef = useRef<NodeJS.Timeout | null>(null);

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const disabledDate = (current: Dayjs | undefined): boolean => {
    return !!current && current.isBefore(dayjs().startOf("day"));
  };

  const disabledTime = (current: Dayjs | undefined, type: "start" | "end") => {
    if (!current || !current.isSame(dayjs(), "day")) {
      return {};
    }

    const currentHour = dayjs().hour();
    const currentMinute = dayjs().minute();

    return {
      disabledHours: () =>
        Array.from(
          { length: type === "start" ? currentHour : 24 - currentHour - 1 },
          (_, index) => (type === "start" ? index : currentHour + 1 + index)
        ),
      disabledMinutes: () =>
        Array.from(
          { length: type === "start" ? currentMinute : 60 - currentMinute - 1 },
          (_, index) => (type === "start" ? index : currentMinute + 1 + index)
        ),
    };
  };

  const onChange = (value: any, dateString: string[] | string) => {
    setEndDate(dateString);
  };

  const editEndDate = (record: any) => {
    dispatch(
      createCampaignManager({
        endDate: new Date(endDate).toISOString(),
        type: "endDate",
        sendgridCampaignId: record?.sendgridCampaignId,
        fillEndDate: true,
      })
    ).then(() => {
      fetchData();
    });
  };

  const columns: ColumnsType<Campaign> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (record) => (
        <Space>
          {record.status !== "triggered" &&
            record.status !== "preparing" &&
            permissions?.create?.includes("campaignmanager") && (
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => handleCampaignManagerEdit(record)}
                data-testid="edit-campaign-button"
                title="Edit Campaign"
              />
            )}
          {record.status === "triggered" && (
            <Button
              type="text"
              icon={<EyeOutlined />}
              onClick={() => handleCampaignManagerOverview(record)}
              data-testid="preview-campaign-button"
              title="Preview"
            />
          )}
          {record.status === "draft" &&
            permissions?.delete?.includes("campaignmanager") && (
              <DeleteCampaign fetchData={fetchData} formData={record} />
            )}
        </Space>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      sorter: true,
      render: (value: string, record: any) => (
        <>
          {value && value === "preparing" && (
            <Tag color="#F4A460">Preparing</Tag>
          )}
          {value && value === "triggered" && (
            <Tag color="#045B7C">Triggered</Tag>
          )}
          {value && value === "draft" && (
            <>
              <Tag color="grey">Draft</Tag>
              {record.errorMessage && (
                <Tooltip title={`${record.errorMessage}`}>
                  <WarningFilled style={{ color: "red", marginLeft: 4 }} />
                </Tooltip>
              )}
            </>
          )}
          {value && value === "scheduled" && (
            <Tag color="#228B22">Scheduled</Tag>
          )}
          {value !== "draft" && new Date() > new Date(record.endDate) && (
            <Tag color="#FF0000">Expired</Tag>
          )}
        </>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text: string, record: any) => (
        <Text>{text ? new Date(text).toLocaleString() : ""}</Text>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text: string, record: any) => (
        <>
          <Text>{text ? new Date(text).toLocaleString() : ""}</Text>
          <Popconfirm
            title={
              <div>
                <DatePicker
                  disabledDate={(current) => disabledDate(dayjs(current))}
                  disabledTime={(current) =>
                    disabledTime(dayjs(current), "start")
                  }
                  onChange={onChange}
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  defaultValue={dayjs(text)}
                />
              </div>
            }
            onConfirm={() => editEndDate(record)}
            okText="Save"
            cancelText="Cancel"
          >
            {text && (
              <Button
                type="text"
                icon={<CalendarOutlined />}
                title="Edit Date"
                data-testid="edit-campaign-endDate-button"
              />
            )}
          </Popconfirm>
        </>
      ),
    },
    {
      title: "Regulations Requested",
      dataIndex: "regulations",
      key: "regulations",
      width: 200,
      render: (value: string[], record: any) => {
        const regulations = getRegulationsFromLocalStorage();
        const regulationData = value
          ? value?.map(
              (value: any) =>
                regulations?.find(
                  (regulation: any) => regulation.regulationNumber === value
                )?.name || value
            )
          : [];

        if (record?.materials === "Yes") {
          regulationData.push("Material Disclosure");
        }
        if (record?.conflict === "Yes") {
          regulationData.push("Conflict Minerals");
        }
        if (record?.extended === "Yes") {
          regulationData.push("Extended Minerals");
        }
        if (record?.scip === "Yes") {
          regulationData.push("SCIP (WFD)");
        }

        const additionalContent = regulationData.slice(1);
        const content = additionalContent.map((regulation, index) => (
          <Tag color="orange" key={index}>
            {regulation?.toUpperCase()}
          </Tag>
        ));

        return (
          <Tooltip title={content.length > 0 && content}>
            <Tag color="orange">{regulationData[0]?.toUpperCase()}</Tag>
            {additionalContent.length > 0 && (
              <span style={{ color: "orange" }}>
                {" "}
                +{additionalContent.length} more
              </span>
            )}
          </Tooltip>
        );
      },
    },
    {
      title: "Total Suppliers",
      dataIndex: "totalSuppliers",
      key: "totalSuppliers",
      width: 150,
      render: (value: any) => <Text>{value}</Text>,
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ];

  const onSearch = (value: any) => {
    setSearch({
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      listCampaigns({
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc:
          tableSorter.order === "descend"
            ? false
            : Object.keys(tableSorter).length === 0
            ? false
            : true,
        softDelete: false,
        search: search?.query ? search?.query : "",
      })
    )
      .then((response: any) => {
        setCampaigns(response.campaigns);
        setCampaignsCount(response.count);
        setLoading(false);

        const requiresPolling = response.campaigns.some((campaign: any) =>
          ["preparing", "scheduled"].includes(campaign.status)
        );

        if (requiresPolling && !pollingRef.current) {
          startPolling();
        } else if (!requiresPolling && pollingRef.current) {
          stopPolling();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Campaign>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Campaign> | SorterResult<Campaign>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Campaign>);
    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const handleCreateCampaign = () => {
    navigate("create");
  };

  const handleCampaignManagerEdit = (record: Campaign) => {
    const currentDate = new Date();
    const startDate = new Date(record?.startDate);
    const diff = startDate.getTime() - currentDate.getTime();

    if (Math.abs(diff) <= 300000) {
      message.warning(
        "Apologies, but it is not possible to edit or delete a scheduled campaign that is about to be initiated."
      );
    } else {
      navigate(`${record?.sendgridCampaignId}/edit`);
    }
  };

  const handleCampaignManagerOverview = (record: Campaign) => {
    navigate(`${record?.sendgridCampaignId}`, {
      state: { record },
    });
  };

  const startPolling = () => {
    pollingRef.current = setInterval(() => {
      fetchData();
    }, 30000);
  };

  const stopPolling = () => {
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }
  };

  useEffect(() => {
    fetchData(search);
    return () => stopPolling();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  return (
    <>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1rem",
          }}
        >
          <Space>
            <Search
              placeholder="Search using name"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
            {permissions?.create?.includes("campaignmanager") && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleCreateCampaign}
              >
                Create Campaign
              </Button>
            )}
            {/* <Button type="text" icon={<FilterOutlined />} /> */}
          </Space>
        </Space>
        {permissions?.read?.includes("campaignmanager") && (
          <Table
            loading={loading}
            dataSource={campaigns}
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            columns={columns}
            rowKey="_id"
            pagination={{
              ...tablePagination,
              total: campaignsCount,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
          />
        )}
      </ErrorBoundary>
    </>
  );
};

export default CampaignManagerList;
