import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  FilePdfOutlined,
  FilterOutlined,
  DeleteOutlined,
  EyeOutlined,
  WarningFilled,
  ExclamationOutlined,
} from "@ant-design/icons";
import { Space, Typography, Button, Table, Input } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { List } from "../../../../../utils/types/CampaignManager/list";
import { useAppDispatch } from "../../../../../redux/hooks";
import { listCampaignsList } from "../../../../../redux/actions/CampaignManager/listsAction";
import AddListForm from "../../../../../components/forms/AddListForm";
import { getLocalStorage } from "../../../../../utils/localStore";
import ErrorBoundary from "../../../../../utils/errorBoundary";

const { Search } = Input;
const { Text } = Typography;

interface ContactListParams {
  listId: string;
  list: string;
}

const SurveyList: React.FC = () => {
  const dispatch = useAppDispatch();
  const [lists, setLists] = useState<List[]>([]);
  const [listsCount, setListsCount] = useState<number>(0);
  const navigate = useNavigate();
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<List>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [search, setSearch] = useState<any>();

  const handlePreviewClick = (record: any) => {
    const params: ContactListParams = {
      listId: record._id,
      list: record.name,
    };
    navigate(params.listId);
  };

  const columns: ColumnsType<List> = [
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (record: any) => (
        <Space>
          {/* <Button type="text" icon={<DeleteOutlined />} /> */}
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
            data-testid="preview-surveyList-button"
            title="Preview"
          />
        </Space>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Total Suppliers",
      dataIndex: "suppliersCount",
      key: "suppliersCount",
      width: 220,
      render: (value: any, record: any) => {
        const missingCount = record.suppliersMissingCount.filter(
          (e: any) => e === false
        ).length;
        const invalidCount = record.suppliersMissingCount.filter(
          (e: any) => e === "invalid"
        ).length;

        return (
          <Text>
            {value}
            {missingCount >= 1 && (
              <span
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "2px 8px",
                  borderRadius: "5px",
                  display: "inline-flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <span
                  style={{
                    backgroundColor: "white",
                    color: "red",
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "5px",
                  }}
                >
                  <ExclamationOutlined />
                </span>
                {missingCount} missing contact{missingCount > 1 ? "s" : ""}
              </span>
            )}
            {invalidCount >= 1 && (
              <span
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  padding: "2px 8px",
                  borderRadius: "5px",
                  display: "inline-flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <span
                  style={{
                    backgroundColor: "white",
                    color: "orange",
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "5px",
                  }}
                >
                  <ExclamationOutlined />
                </span>
                {invalidCount} invalid contact{invalidCount > 1 ? "s" : ""}
              </span>
            )}
          </Text>
        );
      },
    },
    permissions?.read?.includes("components") && {
      title: "Total Components",
      dataIndex: "componentsCount",
      key: "componentsCount",
      width: 160,
      // sorter: true,
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: true,
      render: (record: any) => <Text>{record}</Text>,
    },
    {
      title: "Last Modified",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      render: (record: any) => <Text>{new Date(record).toLocaleString()}</Text>,
    },
    {
      title: "Modified By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      sorter: true,
      render: (record: any) => <Text>{record}</Text>,
    },
  ].filter(Boolean);

  const onSearch = (value: any) => {
    setSearch({
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      listCampaignsList({
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: search?.query ? search?.query : "",
      })
    )
      .then((response: any) => {
        setLists(response.lists);
        setListsCount(response.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<List>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<List> | SorterResult<List>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<List>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  useEffect(() => {
    fetchData(search);
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1rem",
          }}
        >
          <Space>
            <Search
              placeholder="Search using name"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
            <AddListForm fetchData={fetchData} />
            {/* <Button type="text" icon={<FilterOutlined />} /> */}
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={lists}
          scroll={{
            x: 1500,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: listsCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default SurveyList;
