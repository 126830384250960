import React, { useState, useEffect } from "react";
import { PlusOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Card,
} from "antd";

import { Regulation } from "../../utils/types/regulation";
import { useAppDispatch } from "../../redux/hooks";
import {
  createRegulation,
  updateRegulation,
} from "../../redux/actions/regulationsAction";
import { updateTenantRegulations } from "../../redux/actions/tenantsAction";
import { getLocalStorage } from "../../utils/localStore";

const { Option } = Select;

const RegulationForm: React.FC<{
  type: string;
  fetchData: any;
  id?: string;
  formData?: Regulation;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { workspace } = getLocalStorage("user");
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onHandleChange = (value: any, index: any) => {
    const configuration = form.getFieldValue("configuration");
    const configurationOptions = [
      {
        text: "Internal #",
        value: "internalItemNumber",
        dataType: "Textfield",
        dataValue: null,
        edit: false,
        hidden: false,
        filter: true,
        field: null,
      },
      {
        text: "Manufacturer",
        value: "manufacturer",
        dataType: "Textfield",
        dataValue: null,
        edit: false,
        hidden: false,
        filter: true,
        field: null,
      },
      {
        text: "Compliant",
        value: "complianceOutputs.compliant",
        dataType: "Dropdown",
        dataValue: "Yes,No,Yes with Exemption,Unknown,Out of Scope",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "Version",
        value: "complianceOutputs.version",
        dataType: "Dropdown",
        dataValue: "NA",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "Exemptions",
        value: "complianceOutputs.exemption",
        dataType: "Multilist",
        dataValue: "exemption",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "Substance of Concern",
        value: "complianceOutputs.substance",
        dataType: "Multilist",
        dataValue: "substance",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "Compliance Document",
        value: "complianceDocument",
        dataType: "Fileinput",
        dataValue: null,
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "Exposures",
        value: "exposures",
        dataType: "Dropdown",
        dataValue: "No Exposure,NSRLs,MADLs",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "Type of Toxicity",
        value: "typeOfToxicity",
        dataType: "Dropdown",
        dataValue:
          "Cancer,Developmental Toxicity,Female Reproductive Toxicity,Male Reproductive Toxicity",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "SoC Concentration",
        value: "complianceOutputs.concentrationOfSoc",
        dataType: "Number",
        dataValue: null,
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "PFAS Concentration",
        value: "complianceOutputs.concentrationOfPfas",
        dataType: "Number",
        dataValue: null,
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "Concentration Unit",
        value: "complianceOutputs.concentrationUnit",
        dataType: "Dropdown",
        dataValue: "% weight,ppm",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "SoC Location",
        value: "complianceOutputs.socLocation",
        dataType: "Textfield",
        dataValue: "",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "PFAS Location",
        value: "complianceOutputs.pfasLocation",
        dataType: "Textfield",
        dataValue: "",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "Is PFAS content added intentionally",
        value: "complianceOutputs.pfasIntentionalContent",
        dataType: "Dropdown",
        dataValue: "Yes,No",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
      {
        text: "Purpose of the added PFAS chemical",
        value: "complianceOutputs.pfasIntentionalContentPurpose",
        dataType: "Textfield",
        dataValue: "",
        edit: true,
        hidden: false,
        filter: false,
        field: null,
      },
    ];
    configuration[index] = configurationOptions.find(
      (item) => item.text === value
    );
    form.setFieldsValue({ configuration });
  };

  const onFormSubmit = (values: Regulation) => {
    setLoading(true);
    if (props.type === "create") {
      dispatch(
        createRegulation({
          ...values,
          regulationNumber: values.name.toLowerCase().replaceAll(" ", "_"),
        })
      )
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.id && props.formData) {
      dispatch(
        updateTenantRegulations(props.id, {
          currentLocationOrigin: window.location.origin,
          updates: {
            number: props.formData.regulationNumber,
            regulationUpdates: values,
          },
        })
      )
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(
        updateRegulation(
          { currentLocationOrigin: window.location.origin, updates: values },
          props.formData._id
        )
      )
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <>
      {permissions[props.type === "create" ? "create" : "update"]?.includes(
        "regulations"
      ) && (
        <>
          <Button
            type={props.type === "create" ? "primary" : "text"}
            title={
              props.type === "create" ? "New Regulation" : "Edit Regulation"
            }
            onClick={showDrawer}
            icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
          >
            {props.type === "create" ? "New Regulation" : null}
          </Button>
          <Drawer
            title={
              props.type === "create"
                ? "Create a New Regulation"
                : "Edit Regulation"
            }
            width={720}
            onClose={onClose}
            open={open}
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={form.submit} type="primary" loading={loading}>
                  {props.type === "create" ? "Submit" : "Save"}
                </Button>
              </Space>
            }
          >
            <Form
              form={form}
              layout="vertical"
              initialValues={
                props.type === "create" ? {} : { ...props.formData }
              }
              onFinish={onFormSubmit}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter regulation name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please enter regulation name"
                      disabled={workspace !== "acquiscompliance"}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="type"
                    label="Type"
                    rules={[{ required: true, message: "Please choose type" }]}
                  >
                    <Select
                      placeholder="Please choose type"
                      disabled={workspace !== "acquiscompliance"}
                    >
                      <Option value="hazard">Hazard</Option>
                      <Option value="regulation">Regulation</Option>
                      <Option value="restriction">Restriction</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="hasExemptions" label="Exemption">
                    <Switch disabled={workspace !== "acquiscompliance"} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="description" label="Description">
                    <Input.TextArea
                      rows={4}
                      placeholder="please enter description"
                      disabled={workspace !== "acquiscompliance"}
                    />
                  </Form.Item>
                </Col>
                {workspace === "acquiscompliance" && (
                  <Col span={24}>
                    <Form.Item name="prompts" label="Prompts">
                      <Input.TextArea
                        rows={4}
                        placeholder="please enter questions for prompts"
                        disabled={workspace !== "acquiscompliance"}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <Form.List name="configuration">
                    {(fields, { add, remove }) => (
                      <Space
                        size="middle"
                        direction="vertical"
                        style={{ display: "flex" }}
                      >
                        {fields.map((field) => (
                          <Card
                            type="inner"
                            size="small"
                            title={`Item ${field.name + 1}`}
                            key={field.key}
                            extra={
                              workspace === "acquiscompliance" && (
                                <CloseOutlined
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                              )
                            }
                          >
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="Name"
                                  name={[field.name, "text"]}
                                  rules={[
                                    { required: true, message: "Missing text" },
                                  ]}
                                >
                                  <Select
                                    placeholder="Please choose text"
                                    onChange={(value) =>
                                      onHandleChange(value, field.name)
                                    }
                                    disabled={workspace !== "acquiscompliance"}
                                  >
                                    <Option value="Internal #">
                                      Internal #
                                    </Option>
                                    <Option value="Manufacturer">
                                      Manufacturer
                                    </Option>
                                    <Option value="Compliant">Compliant</Option>
                                    <Option value="Version">Version</Option>
                                    <Option value="Exemptions">
                                      Exemptions
                                    </Option>
                                    <Option value="Substance of Concern">
                                      Substance of Concern
                                    </Option>
                                    <Option value="Compliance Document">
                                      Compliance Document
                                    </Option>
                                    <Option value="Exposures">Exposures</Option>
                                    <Option value="Types of Toxicity">
                                      Types of Toxicity
                                    </Option>
                                    <Option value="Concentration of PFAS">
                                      Concentration of PFAS
                                    </Option>
                                    <Option value="Concentration Unit">
                                      Concentration Unit
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="Value"
                                  name={[field.name, "value"]}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Missing value",
                                    },
                                  ]}
                                  hidden
                                >
                                  <Input
                                    placeholder="Please enter value"
                                    disabled={workspace !== "acquiscompliance"}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="Data Type"
                                  name={[field.name, "dataType"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing data type",
                                    },
                                  ]}
                                  extra="Select the field data type"
                                >
                                  <Select
                                    placeholder="Please choose data type"
                                    disabled={workspace !== "acquiscompliance"}
                                  >
                                    <Option value="Textfield">Textfield</Option>
                                    <Option value="Fileinput">Fileinput</Option>
                                    <Option value="Dropdown">Dropdown</Option>
                                    <Option value="Autocomplete">
                                      Autocomplete
                                    </Option>
                                    <Option value="Number">Number</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="Data Value"
                                  name={[field.name, "dataValue"]}
                                  extra="Dropdown values separated by comma"
                                >
                                  <Input placeholder="Please enter data value" />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name={[field.name, "edit"]}
                                  label="Edit"
                                  extra="Mark value as editable"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name={[field.name, "hidden"]}
                                  label="Table"
                                  extra="Hide in table view"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name={[field.name, "filter"]}
                                  label="Filter"
                                  extra="Mark value as filterable"
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                        {workspace === "acquiscompliance" && (
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Configuration
                          </Button>
                        )}
                      </Space>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Form>
          </Drawer>
        </>
      )}
    </>
  );
};

export default RegulationForm;
