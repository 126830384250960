import React, { useEffect, useState } from "react";
import { Space, Table, Typography } from "antd";

import { readScipProductComponents } from "../../../redux/actions/scipAction";
import { useAppDispatch } from "../../../redux/hooks";
import type { ColumnsType } from "antd/es/table";
import ScipForm from "../../../components/forms/ScipForm";
import { getLocalStorage } from "../../../utils/localStore";
import TableExpandScipChildComponent from "./TableExpandScipChildComponents";
const { Title, Text } = Typography;

const TableExpandScipComponent: React.FC<{
  productId: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [scipProductComponents, setScipProductComponents] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const fetchData = () => {
    setLoading(true);
    dispatch(readScipProductComponents(props?.productId, {})).then(
      (response) => {
        setScipProductComponents(response?.scipProductComponents);
        setLoading(false);
      }
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      width: 150,
      render: (_: string, record: any) => (
        <>
          <Space>
            {permissions?.update.includes("scip") && (
              <ScipForm type="edit" formData={record} fetchData={fetchData} />
            )}
            {permissions?.update.includes("scip") && (
              <ScipForm
                type="preview"
                formData={record}
                fetchData={fetchData}
              />
            )}
          </Space>
        </>
      ),
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
      width: 150,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      width: 200,
      render: (manufacturer: any) => (
        <Text>
          {manufacturer.itemNumber}
          <br></br>
          <small>{manufacturer.name}</small>
        </Text>
      ),
    },
    {
      title: "SVHC Version",
      dataIndex: "compliances.complianceOutputs.version",
      key: "compliances.complianceOutputs.version",
      sorter: true,
      width: 200,
      render: (_, record: any) => (
        <Text>
          {record && record.compliances
            ? record.compliances?.complianceOutputs?.version
            : ""}
        </Text>
      ),
    },
    {
      title: "Substance of Concern",
      dataIndex: "compliances.complianceOutputs.substance",
      key: "compliances.complianceOutputs.substance",
      sorter: true,
      width: 200,
      hidden: false,
      render: (text: any, record: any) => (
        <Text
          style={{ width: 200 }}
          ellipsis={{
            tooltip: record.compliances?.complianceOutputs?.substance,
          }}
        >
          {record && record.compliances
            ? record.compliances?.complianceOutputs?.substance
            : ""}
        </Text>
      ),
    },
    {
      title: "Article ID Type",
      dataIndex: "PrimaryArticleIdentifierType",
      key: "PrimaryArticleIdentifierType",
      sorter: true,
      width: 150,
      render: (value: any) => <Text>{value ? value?.text : ""}</Text>,
    },
    {
      title: "Article ID Value",
      dataIndex: "PrimaryArticleIdentifierValue",
      key: "PrimaryArticleIdentifierValue",
      sorter: true,
      width: 150,
    },
    {
      title: "Dataset Status",
      dataIndex: "Status",
      key: "Status",
      sorter: true,
      width: 200,
      render: (text: any) => (
        <Text
          type={
            text === "Completed"
              ? "success"
              : text === "Query Raised"
              ? "danger"
              : "warning"
          }
        >
          {" "}
          {text}{" "}
        </Text>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>
          {text ? new Date(text).toLocaleString() : ""}
          <br />
          {record?.createdBy}
        </Text>
      ),
    },
  ];

  useEffect(() => {
    if (props.productId) {
      fetchData();
    }
  }, [props.productId]);

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={scipProductComponents}
        pagination={false}
        rowKey={(record) => record._id}
        scroll={{
          x: 1000,
          y: "calc(100vh - 20px)",
        }}
        expandable={{
          expandedRowRender: (record: any) => (
            <TableExpandScipChildComponent componentId={record._id} />
          ),
          rowExpandable: (record) => record.components,
        }}
      />
    </>
  );
};

export default TableExpandScipComponent;
