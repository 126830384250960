import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FilePdfOutlined,
  FilterOutlined,
  FileSyncOutlined,
  MoreOutlined,
  EyeOutlined,
  ControlOutlined,
} from "@ant-design/icons";

import {
  Space,
  Typography,
  Button,
  Table,
  Input,
  Spin,
  Tag,
  Dropdown,
  Checkbox,
} from "antd";
import type { MenuProps } from "antd";

import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";

import { Supplier } from "../../../../utils/types/supplier";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  listSuppliers,
  listSuppliersFilters,
  generateSupplier,
} from "../../../../redux/actions/suppliersAction";

import SupplierModal from "../../../../components/forms/SupplierForm";
import { setLocalStorage, getLocalStorage } from "../../../../utils/localStore";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import AddToCampaignListModal from "../../../../components/modals/AddToCampaignListModal";

const { Search } = Input;
const { Title, Text } = Typography;

const Suppliers: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [supplierCount, setSupplierCount] = useState<number>(0);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

  const [search, setSearch] = useState<any>();

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Supplier>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [filterData, setFilterData] = useState<any>({});
  const [filterOptions, setFilterOptions] = useState<any>({});

  const onSelectSuppliers = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Supplier[]
  ) => {
    setSelectedProducts(selectedRows);
  };

  const supplierSelection: TableRowSelection<Supplier> = {
    selectedRowKeys: selectedProducts.map((e) => e._id),
    onChange: onSelectSuppliers,
    preserveSelectedRowKeys: true,
  };

  const onSearch = (value: any) => {
    setSearch({
      searchField: true,
      query:
        value?.key === "Enter"
          ? value?.target?.value.replace(/[()/[\]]/g, "\\$&").trim()
          : value.replace(/[()/[\]]/g, "\\$&").trim(),
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<string[]>([]);
  const [selectedWebsite, setSelectedWebsite] = useState<string[]>([]);
  const [selectedSupplierType, setSelectedSupplierType] = useState<string[]>(
    []
  );
  const [selectedSupplierStatus, setSelectedSupplierStatus] = useState<
    string[]
  >([]);
  const [selectedOutOfScope, setSelectedOutOfScope] = useState<string[]>([]);
  const [selectedWhereUsed, setSelectedWhereUsed] = useState<string[]>([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string[]>([]);
  const [selectedEmailAddress, setSelectedEmailAddress] = useState<string[]>(
    []
  );
  const [key, setKey] = useState<string | null>(null);
  const [advancedropdown, setadvanceDropdown] = useState(false);

  interface SupplierProfileParams {
    id: string;
  }

  const handlePreviewClick = (record: any) => {
    const params: SupplierProfileParams = {
      id: record._id,
    };
    navigate(`${params.id}`);
    // setLocalStorage("supplier", record.name);
  };

  const handleDeleteClick = (record: any) => {
    console.log("Delete record", record);
  };

  const fetchData = () => {
    const filters: { [x: string]: FilterValue | null }[] = [];
    Object.keys(tableFilters).forEach((key) => {
      if (tableFilters[key] && tableFilters[key]?.length) {
        filters.push({ [key]: tableFilters[key] });
      }
    });
    setLoading(true);
    dispatch(
      listSuppliers({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["name"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        searchField:
          !filters.length && search?.searchField ? search?.query : "",
        filtersUsed: filters.length ? "useFilters" : false,
        filters: filters.length
          ? JSON.stringify({
              elements: filters,
              path: [],
            })
          : [],
      })
    )
      .then((response: any) => {
        setSuppliers(response.suppliers);
        setSupplierCount(response.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const [menuVisible, setMenuVisible] = useState(false);
  const fetchFilterData = (columnName: string) => {
    setLoadingData(true);
    dispatch(listSuppliersFilters({ column: columnName })).then(
      (response: any) => {
        setFilterData((prev: any) => ({ ...prev, [columnName]: response }));
        setFilterOptions((prev: any) => ({ ...prev, [columnName]: response }));
        // setCurrentFilter("");
        setLoadingData(false);
        setMenuVisible(true);
      }
    );
  };

  const onClickGenerate = () => {
    dispatch(generateSupplier({ alert: true })).then(() => {
      fetchData();
    });
  };
  const handleTableChange: TableProps<Supplier>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Supplier> | SorterResult<Supplier>[]
  ) => {
    const entries = Object.entries(filters);
    const filteredEntries = entries.filter(([key, value]) => value !== null);
    const cleanFilters = Object.fromEntries(filteredEntries);
    setTablePagination(pagination);
    setTableFilters(cleanFilters);
    setTableSorter(sorter as SorterResult<Supplier>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  // const menu = (value: any): MenuProps => ({
  //   items: [
  //     (
  //       permissions?.delete?.includes("suppliers") &&
  //       {
  //         key: "Delete",
  //         onClick: () => handleDeleteClick(value),
  //         label: "Delete",
  //         icon: <DeleteOutlined />,
  //       }
  //     ),
  //     {
  //       key: "Preview",
  //       onClick: () => handlePreviewClick(value),
  //       label: "Preview",
  //       icon: <EyeOutlined />,
  //     },
  //   ],
  // });
  const createDropdownMenu = (
    key: string,
    // options: { label: string; value: string }[],
    selectedOptions: string[],
    setSelectedOptions: React.Dispatch<React.SetStateAction<any>>
  ): MenuProps["items"] => {
    const handleSearch = (searchQuery: string) => {
      if (searchQuery) {
        const options = searchQuery
          ? filterData[key]?.filter((option: any) =>
              option?.text?.toLowerCase().includes(searchQuery.toLowerCase())
            )
          : filterData[key];
        setFilterOptions((prev: any) => ({ ...prev, [key]: options }));
      } else {
        setFilterOptions(filterData);
      }
    };
    return [
      {
        key: "search",
        label: (
          <div onClick={(e) => e.stopPropagation()}>
            <Search
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
              onSearch={handleSearch}
            />
          </div>
        ),
      },
      {
        key: "checkbox-group",
        label: (
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox.Group
              style={{ display: "block" }}
              options={filterOptions[key]?.map((option: any) => ({
                label: option.text,
                value: option.value,
              }))}
              value={selectedOptions}
              onChange={(checkedValues) => {
                if (setSelectedOptions) {
                  setSelectedOptions(checkedValues as string[]);
                }
              }}
            />
          </div>
        ),
      },
      {
        key: "confirm",
        label: (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                const advancedFilters = {
                  website: selectedWebsite || null,
                  countryOfOrigin: selectedCountries || null,
                  assignedTo: selectedAssignedTo || null,
                  type: selectedSupplierType || null,
                  status: selectedSupplierStatus || null,
                  outOfScope: selectedOutOfScope || null,
                  country: selectedWhereUsed || null,
                  phoneNumber: selectedPhoneNumber || null,
                  "contacts.emailAddress": selectedEmailAddress || null,
                };
                setTableFilters((prevFilters) => ({
                  ...prevFilters,
                  ...Object.fromEntries(
                    Object.entries(advancedFilters).filter(
                      ([, value]) => value && value.length > 0
                    )
                  ),
                }));
                setTablePagination({
                  ...tablePagination,
                  current: 1,
                });
                fetchData();
              }}
            >
              OK
            </Button>
            {(selectedWebsite.length > 0 ||
              selectedCountries.length > 0 ||
              selectedAssignedTo.length > 0 ||
              selectedSupplierType.length > 0 ||
              selectedSupplierStatus.length > 0 ||
              selectedOutOfScope.length > 0 ||
              selectedWhereUsed.length > 0 ||
              selectedPhoneNumber.length > 0 ||
              selectedEmailAddress.length > 0) && (
              <Button
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedOptions([]);
                  setSelectedWebsite([]);
                  setSelectedCountries([]);
                  setSelectedAssignedTo([]);
                  setSelectedSupplierType([]);
                  setSelectedSupplierStatus([]);
                  setSelectedOutOfScope([]);
                  setSelectedWhereUsed([]);
                  setSelectedPhoneNumber([]);
                  setSelectedEmailAddress([]);
                }}
              >
                Reset
              </Button>
            )}
          </Space>
        ),
      },
    ];
  };
  const advancedFilterMenu = [
    {
      key: "Website",
      label: (
        <Dropdown
          menu={{
            items: createDropdownMenu(
              "website",
              selectedWebsite,
              setSelectedWebsite
            ),
          }}
          open={key === "Website" && menuVisible}
          trigger={["click"]}
          placement="bottomLeft"
          onOpenChange={(open) => {
            if (open) {
              setKey("Website");
              setMenuVisible(open);
              fetchFilterData("website");
            } else {
              setMenuVisible(false);
              setKey(null);
            }
          }}
          overlayStyle={{ pointerEvents: "auto" }}
        >
          <span>Website</span>
        </Dropdown>
      ),
    },
    {
      key: "Country Of Origin",
      label: (
        <Dropdown
          menu={{
            items: createDropdownMenu(
              "countryOfOrigin",
              selectedCountries,
              setSelectedCountries
            ),
          }}
          open={key === "Country Of Origin" && menuVisible}
          trigger={["click"]}
          placement="bottomLeft"
          overlayStyle={{ pointerEvents: "auto" }}
          onOpenChange={(open) => {
            if (open) {
              setKey("Country Of Origin");
              setMenuVisible(open);
              fetchFilterData("countryOfOrigin");
            } else {
              setMenuVisible(false);
              setKey(null);
            }
          }}
        >
          <span>Country of Origin</span>
        </Dropdown>
      ),
    },
    {
      key: "Assigned To",
      label: (
        <Dropdown
          menu={{
            items: createDropdownMenu(
              // assignedToOptions,
              "assignedTo",
              selectedAssignedTo,
              setSelectedAssignedTo
            ),
          }}
          open={key === "Assigned To" && menuVisible}
          // open={menuVisible}
          trigger={["click"]}
          placement="bottomLeft"
          overlayStyle={{ pointerEvents: "auto" }}
          // onOpenChange={(open) => open && fetchFilterData("assignedTo")}
          onOpenChange={(open) => {
            if (open) {
              setKey("Assigned To");
              setMenuVisible(open);
              fetchFilterData("assignedTo");
            } else {
              setMenuVisible(false);
              setKey(null);
            }
          }}
        >
          <span>Assigned To</span>
        </Dropdown>
      ),
    },
    {
      key: "Supplier Type",
      label: (
        <Dropdown
          menu={{
            items: createDropdownMenu(
              "type",
              selectedSupplierType,
              setSelectedSupplierType
            ),
          }}
          open={key === "Supplier Type" && menuVisible}
          trigger={["click"]}
          placement="bottomLeft"
          overlayStyle={{ pointerEvents: "auto" }}
          onOpenChange={(open) => {
            if (open) {
              setMenuVisible(open);
              setKey("Supplier Type");
              fetchFilterData("type");
            } else {
              setMenuVisible(false);
              setKey(null);
            }
          }}
        >
          <span>Supplier Type</span>
        </Dropdown>
      ),
    },
    {
      key: "Supplier Status",
      label: (
        <Dropdown
          menu={{
            items: createDropdownMenu(
              // componentStatusOptions,
              "status",
              selectedSupplierStatus,
              setSelectedSupplierStatus
            ),
          }}
          open={key === "Supplier Status" && menuVisible}
          trigger={["click"]}
          placement="bottomLeft"
          overlayStyle={{ pointerEvents: "auto" }}
          onOpenChange={(open) => {
            if (open) {
              setMenuVisible(open);
              setKey("Supplier Status");
              fetchFilterData("status");
            } else {
              setMenuVisible(false);
              setKey(null);
            }
          }}
        >
          <span>Supplier Status</span>
        </Dropdown>
      ),
    },
    {
      key: "Out of scope for",
      label: (
        <Dropdown
          menu={{
            items: createDropdownMenu(
              "outOfScope",
              selectedOutOfScope,
              setSelectedOutOfScope
            ),
          }}
          open={key === "Out of scope for" && menuVisible}
          trigger={["click"]}
          placement="bottomLeft"
          overlayStyle={{ pointerEvents: "auto" }}
          onOpenChange={(open) => {
            if (open) {
              setMenuVisible(open);
              setKey("Out of scope for");
              fetchFilterData("outOfScope");
            } else {
              setMenuVisible(false);
              setKey(null);
            }
          }}
        >
          <span>Out of scope for</span>
        </Dropdown>
      ),
    },
    {
      key: "Country",
      label: (
        <Dropdown
          menu={{
            items: createDropdownMenu(
              "country",
              selectedWhereUsed,
              setSelectedWhereUsed
            ),
          }}
          open={key === "Country" && menuVisible}
          trigger={["click"]}
          placement="bottomLeft"
          overlayStyle={{ pointerEvents: "auto" }}
          onOpenChange={(open) => {
            if (open) {
              setMenuVisible(open);
              setKey("Country");
              open && fetchFilterData("country");
            } else {
              setMenuVisible(false);
              setKey(null);
            }
          }}
        >
          <span>Country</span>
        </Dropdown>
      ),
    },
    {
      key: "Company Phone",
      label: (
        <Dropdown
          menu={{
            items: createDropdownMenu(
              "phoneNumber",
              selectedPhoneNumber,
              setSelectedPhoneNumber
            ),
          }}
          open={key === "Company Phone" && menuVisible}
          trigger={["click"]}
          placement="bottomLeft"
          overlayStyle={{ pointerEvents: "auto" }}
          onOpenChange={(open) => {
            if (open) {
              setMenuVisible(open);
              setKey("Company Phone");
              open && fetchFilterData("phoneNumber");
            } else {
              setMenuVisible(false);
              setKey(null);
            }
          }}
        >
          <span>Company Phone</span>
        </Dropdown>
      ),
    },
    {
      key: "Email Address",
      label: (
        <Dropdown
          menu={{
            items: createDropdownMenu(
              "contacts.emailAddress",
              selectedEmailAddress,
              setSelectedEmailAddress
            ),
          }}
          open={key === "Email Address" && menuVisible}
          trigger={["click"]}
          placement="bottomLeft"
          overlayStyle={{ pointerEvents: "auto" }}
          onOpenChange={(open) => {
            if (open) {
              setMenuVisible(open);
              setKey("Email Address");
              open && fetchFilterData("contacts.emailAddress");
            } else {
              setMenuVisible(false);
              setKey(null);
            }
          }}
        >
          <span>Email Address</span>
        </Dropdown>
      ),
    },
  ];

  const columns: ColumnsType<Supplier> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (text: any, record: any) => (
        <Space>
          {permissions?.update?.includes("suppliers") && (
            <SupplierModal
              type="edit"
              formData={record}
              fetchData={fetchData}
            />
          )}
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
            data-testid="preview-supplier-button"
            title="Preview"
          />
          {/* <Dropdown menu={menu(record)}>
            <Space>
              <Button type="text" icon={<MoreOutlined />} />
            </Space>
          </Dropdown> */}
        </Space>
      ),
    },
    {
      title: "Supplier ID",
      dataIndex: "number",
      key: "number",
      sorter: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "name",
      key: "name",
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("name"),
      filters: filterData?.name || [],
      filteredValue: tableFilters.name || null,
      filterSearch: true,
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("country"),
      filters: filterData?.country || [],
      filteredValue: tableFilters.country || null,
      filterSearch: true,
      sorter: true,
    },
    {
      title: "Active Contacts",
      dataIndex: "activeContacts",
      key: "activeContacts",
      // render: (contacts) => (
      //   <Text>
      //     {contacts &&
      //     contacts.length > 0 &&
      //     contacts.find((e: any) => e.isPrimary)
      //       ? contacts.find((e: any) => e.isPrimary).firstName
      //       : ""}
      //   </Text>
      // ),
    },
    permissions?.read?.includes("components") && {
      title: "Active Components",
      dataIndex: "activeComponents",
      key: "activeComponents",
    },
    {
      title: "In Scope for",
      dataIndex: "inScope",
      key: "inScope",
      ellipsis: true,
      render: (inScope: any) => (
        <Text>{inScope?.map((e: any) => e.name).join(", ")}</Text>
      ),
    },
    {
      title: "Last Reported",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ].filter(Boolean);

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);
  useEffect(() => {
    if (!menuVisible && key == null) {
      setadvanceDropdown(false);
      setSelectedWebsite([]);
      setSelectedCountries([]);
      setSelectedAssignedTo([]);
      setSelectedSupplierType([]);
      setSelectedSupplierStatus([]);
      setSelectedOutOfScope([]);
      setSelectedWhereUsed([]);
      setSelectedPhoneNumber([]);
      setSelectedEmailAddress([]);
    }
  }, [menuVisible, key]);

  return (
    <>
      <ErrorBoundary>
        <Spin fullscreen spinning={loadingData} />
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Suppliers
            </Title>
          </div>
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(tableFilters).length > 0 &&
              Object.keys(tableFilters).reduce<JSX.Element[]>((acc, key) => {
                const elements =
                  tableFilters[key]?.map((item, idx) => (
                    <Tag
                      className="filter-tag"
                      key={`${key}-${idx}`}
                      bordered={false}
                      closable
                      onClose={() => {
                        const tablefiltrs = { ...tableFilters };
                        const arr = tablefiltrs[key];
                        if (arr) {
                          const arrIndex = arr?.indexOf(item);
                          if (arrIndex !== -1) {
                            arr.splice(arrIndex, 1);
                          }
                          setTableFilters({ ...tablefiltrs });
                        }
                      }}
                    >
                      {typeof item === "string" && item}
                    </Tag>
                  )) || [];
                acc.push(...elements);
                return acc;
              }, [])}
            {Object.keys(tableFilters).length > 0 ? (
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setTableFilters({});
                  setSearch({});
                  setSelectedWebsite([]);
                  setSelectedCountries([]);
                  setSelectedAssignedTo([]);
                  setSelectedSupplierType([]);
                  setSelectedSupplierStatus([]);
                  setSelectedOutOfScope([]);
                  setSelectedWhereUsed([]);
                  setSelectedPhoneNumber([]);
                  setSelectedEmailAddress([]);
                }}
              >
                Reset Filters
              </Button>
            ) : (
              <Search
                placeholder="Search using supplier name, number, country, status, Last reported, email, contact number, country of origin, supplier type,assigned to"
                onSearch={onSearch}
                onPressEnter={onSearch}
                allowClear
                style={{ width: 350 }}
              />
            )}
            <Dropdown
              menu={{ items: advancedFilterMenu }}
              open={advancedropdown}
              trigger={["click"]}
            >
              <Button
                type="primary"
                icon={<ControlOutlined />}
                onClick={() => setadvanceDropdown(true)}
              >
                Advance Filters
              </Button>
            </Dropdown>
            <AddToCampaignListModal
              selectedItems={selectedProducts}
              onCancel={() => setSelectedProducts([])}
              type="suppliersAdd"
            />
            {permissions?.create?.includes("components") && (
              <Button
                type="primary"
                onClick={onClickGenerate}
                icon={<FileSyncOutlined />}
                data-testid="generate-button"
                title="Generate Suppliers"
              >
                Generate
              </Button>
            )}
            {permissions?.create?.includes("suppliers") && (
              <SupplierModal type="create" fetchData={fetchData} />
            )}
            {/* <Button type="text" icon={<FilterOutlined />} /> */}
          </Space>
        </Space>
        {permissions?.read?.includes("suppliers") && (
          <Table
            loading={loading}
            dataSource={suppliers}
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            columns={columns}
            rowKey="_id"
            rowSelection={supplierSelection}
            pagination={{
              ...tablePagination,
              total: supplierCount,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
          />
        )}
      </ErrorBoundary>
    </>
  );
};

export default Suppliers;
