import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import {
  listRegulationExemptions,
  listRegulations,
  listRegulationSubtances,
} from "../../redux/actions/regulationsAction";
import { useAppDispatch } from "../../redux/hooks";
import {
  bulkProductAnalytics,
  createDeclarationCertificate,
  productAnalytics,
  uploadDeclaration,
} from "../../redux/actions/productsAction";
import { baseUrl } from "../../utils/apiHelper";

import { getLocalStorage } from "../../utils/localStore";
import axios from "axios";
import logo from "../../assets/logo-icon.png";
import jsPDF from "jspdf";
import { myfont } from "../../assets/font/WaterBrush-Regular-normal.js";
import { Rubik } from "../../assets/font/Rubik-Regular-normal.js";
import { RubikBold } from "../../assets/font/RubikBold-normal.js";
import logoIcon from "../../assets/logo-icon.png";
// import "jspdf-autotable"
import autoTable from "jspdf-autotable";

interface CertificateProps {
  yesDescription: string;
  noDescription: string;
  commonDescription: string;
  title: string;
  authorisedBy: string;
  designation: string;
  signature: string;
  certificateLogo: null;
}

const { Text } = Typography;

const CreateBulkDeclarationModal: React.FC<{
  products: any;
  fetchData: any;
}> = (props) => {
  const [open, setOpen] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const user = getLocalStorage("user");
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const pdfRef = useRef<any>(null);
  const [regulationOptions, setRegulationOptions] = useState<any[]>([]);
  const [regulationName, setRegulationName] = useState("");
  const [regulationNumber, setRegulationNumber] = useState("");
  const [productAnalytic, setProductAnalytic] = useState<any>();
  const [pageHeight, setPageHeight] = useState<number>(0);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [exemption, setExemption] = useState<boolean | null>(null);
  const [substance, setSubstance] = useState<boolean | null>(null);
  const [certificate, setCertificate] = useState(false);
  const [certificateData, setCertificateData] = useState<CertificateProps>({
    yesDescription: "",
    noDescription: "",
    commonDescription: "",
    title: "",
    authorisedBy: "",
    designation: "",
    signature: "",
    certificateLogo: null,
  });
  const [tenantLogo, setTenantLogo] = React.useState<string>();
  const [certificateLogo, setCertificateLogo] = React.useState<string>();
  const [declarationLogo, setDeclarationLogo] = React.useState<string>();
  const [loading, setLoading] = useState(false);
  const [scipData, setScipData] = useState<boolean | null>(null);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const [editExemptions, setEditExemptions] = useState<boolean>(false);
  const [editSubstances, setEditSubstances] = useState<boolean>(false);
  const [searchExemptions, setSearchExemptions] = useState<any[]>([]);
  const [searchSubstances, setSearchSubstances] = useState<any[]>([]);

  const chartStyle: React.CSSProperties = {
    padding: 50,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
  };
  const chart = <div style={chartStyle} />;

  const calculateStatus = (item: any, regulationNumber: string) => {
    const completedValue = item.regulations?.find(
      (e: any) => e.number === regulationNumber
    );

    if (!completedValue) return "Unknown";

    if (parseFloat(completedValue.completedStatus) !== 100.0)
      return "Un-determined/ Under review";
    if (parseFloat(completedValue.nonCompliantStatus) > 0)
      return "Non-Compliant";
    if (
      parseFloat(completedValue.completedStatus) === 100.0 &&
      parseFloat(completedValue.compliantStatus) === 100.0 &&
      completedValue.exemptions.length > 0
    )
      return "Compliant with Exemption";
    return "Compliant";
  };

  const [tableData, setTableData] = useState(
    productAnalytic?.map((item: any) => ({
      ...item,
      status: calculateStatus(item, regulationNumber),
    }))
  );

  const handleDoubleClick = (type: string, index: number) => {
    setTableData((prev: any) =>
      prev.map((item: any, i: number) =>
        i === index
          ? {
              ...item,
              ...(type === "status" && {
                editStatus: true,
              }),
              ...(type === "exemption" && {
                forceEditExemptions: true,
              }),
              ...(type === "substance" && {
                forceEditSubstances: true,
              }),
            }
          : item
      )
    );
  };

  const handleStatusChange = (index: number, newStatus: string) => {
    setTableData((prev: any) => {
      const tableValues = prev.map((item: any, i: any) =>
        i === index
          ? {
              ...item,
              status: newStatus,
              editStatus: true,
              forceEdited: true,
              forceEditExemptions:
                newStatus === "Compliant with Exemption" ? true : false,
              forceEditSubstances: newStatus === "Non-Compliant" ? true : false,
            }
          : item
      );
      const exemptionValid = tableValues.some(
        (item: any) => item.status === "Compliant with Exemption"
      );
      setExemption(exemptionValid);
      const substanceValid = tableValues.some(
        (item: any) => item.status === "Non-Compliant"
      );
      setSubstance(substanceValid);
      setScipData(substanceValid);
      return tableValues;
    });

    if (newStatus === "Compliant with Exemption") {
      setEditExemptions(true);
    }
    if (newStatus === "Non-Compliant") {
      setEditSubstances(true);
    }
  };

  const handleBlur = (type: string, index: number) => {
    setTableData((prev: any) =>
      prev.map((item: any, i: number) =>
        i === index
          ? {
              ...item,
              ...(type === "status" && {
                editStatus: false,
              }),
              ...(type === "exemption" && {
                forceEditExemptions: false,
              }),
              ...(type === "substance" && {
                forceEditSubstances: false,
              }),
            }
          : item
      )
    );
  };

  const handleExemptionChange = (index: number, value: any) => {
    setTableData((prev: any) =>
      prev.map((item: any, i: number) =>
        i === index
          ? {
              ...item,
              exemptions:
                item.exemptions?.length > 0
                  ? item.exemptions?.concat(
                      value.filter((e: any) => !item.exemptions?.includes(e))
                    )
                  : value,
            }
          : item
      )
    );
  };

  const handleSubstanceChange = (index: number, value: any) => {
    setTableData((prev: any) =>
      prev.map((item: any, i: number) =>
        i === index
          ? {
              ...item,
              substances:
                item.substances?.length > 0
                  ? item.substances?.concat(
                      value.filter((e: any) => !item.substances?.includes(e))
                    )
                  : value,
            }
          : item
      )
    );
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
    setChildrenDrawer(false);
    setCertificate(false);
    props.fetchData();
    setTableData([]);
  };

  const getCurrentDate = (): string => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    return date;
  };

  const currentDate = getCurrentDate();

  // const showChildrenDrawer = () => {
  //   setChildrenDrawer(true);
  // };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const fetchProductAnalytics = () => {
    setLoading(true);
    dispatch(
      bulkProductAnalytics({
        skipMutation: true,
        id: props.products.map((e: any) => e._id),
      })
    ).then((response: any) => {
      setProductAnalytic(response.productAnalytics);
      setLoading(false);
    });
  };

  useEffect(() => {
    setTableData(
      productAnalytic?.map((item: any) => ({
        ...item,
        status: calculateStatus(item, regulationNumber),
        editStatus: false,
      }))
    );
  }, [productAnalytic, regulationNumber]);

  useEffect(() => {
    if (tableData?.some((item: any) => item.editStatus)) {
      handleSearchExemptions("", regulationNumber);
      handleSearchSubstances("", regulationNumber);
    }
  }, [tableData]);

  const fetchRegulations = (value: string) => {
    const values = value.split(", ");
    let filters = {
      name: { $regex: value, $options: "i" },
    };
    if (values.length > 1) {
      filters = {
        name: { $in: values } as {
          $in: string[];
          $regex: string;
          $options: string;
        },
      };
    }
    dispatch(
      listRegulations({
        pageSize: 0,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: true,
        filters: JSON.stringify(filters),
      })
    ).then((response) => {
      setRegulationOptions(
        response.regulations.map((regulation: any) => ({
          label: regulation.name,
          value: regulation.regulationNumber,
          default: regulation,
        }))
      );
    });
  };

  const handleSearchExemptions = (search: any, regulation: any) => {
    dispatch(
      listRegulationExemptions({
        search: true,
        pageNum: 1,
        pageSize: 10,
        query: search,
        softDelete: false,
        filters: search
          ? JSON.stringify({
              softDelete: false,
              $or: [
                {
                  exemption: {
                    $regex: search.replace(/[()/[\]]/g, "\\$&"),
                    $options: "i",
                  },
                },
              ],
              regulationNumber: regulation,
            })
          : JSON.stringify({
              regulationNumber: regulation,
            }),
      })
    )
      .then((response: any) => {
        setSearchExemptions(
          response.exemptions.map((e: any) => ({
            value: e.exemption,
            ...e,
          }))
        );
      })
      .catch((e) => console.log(e));
  };

  const handleSearchSubstances = (search: any, regulation: any) => {
    dispatch(
      listRegulationSubtances({
        search: true,
        pageNum: 1,
        pageSize: 10,
        query: search,
        softDelete: false,
        filters: search
          ? JSON.stringify({
              softDelete: false,
              $or: [
                {
                  substance: {
                    $regex: search.replace(/[()/[\]]/g, "\\$&"),
                    $options: "i",
                  },
                },
              ],
              regulationNumber: regulation,
            })
          : JSON.stringify({
              regulationNumber: regulation,
            }),
      })
    )
      .then((response: any) => {
        setSearchSubstances(
          response.substances.map((e: any) => ({
            value: e.substance,
            ...e,
          }))
        );
      })
      .catch((e) => console.log(e));
  };

  const handleRegulations = (value: any, option: any) => {
    setRegulationName(option?.default?.name);
    setRegulationNumber(value);

    const anaytics = productAnalytic?.filter((e: any) =>
      e.regulations?.some((item: any) => item.number === value)
    );

    const exemptionValid = anaytics?.some((i: any) =>
      i.regulations?.some(
        (analysis: any) =>
          analysis.number === value &&
          parseFloat(analysis.completedStatus) === 100.0 &&
          parseFloat(analysis.compliantStatus) === 100.0 &&
          analysis.exemptions.length > 0
      )
    );
    setExemption(exemptionValid);

    const substanceValid = anaytics?.some((i: any) =>
      i.regulations?.some(
        (analysis: any) =>
          analysis.number === value &&
          parseFloat(analysis.completedStatus) === 100.0 &&
          parseFloat(analysis.nonCompliantStatus) > 0 &&
          analysis.substances.length > 0
      )
    );

    setSubstance(substanceValid);

    const scipValid = anaytics?.some((i: any) =>
      i.regulations?.some(
        (analysis: any) =>
          analysis.number === value &&
          parseFloat(analysis.completedStatus) === 100.0 &&
          parseFloat(analysis.nonCompliantStatus) > 0 &&
          anaytics?.some((v: any) => v.scipNumber)
      )
    );

    setScipData(scipValid);
    setCertificate(true);
  };

  const onSubmit = () => {
    const user = getLocalStorage("user");
    const certificatefilterData = regulationOptions?.find(
      (val: any) => val.value === regulationNumber
    )?.default;
    const declarationData = user?.declaration;
    setCertificateData({
      yesDescription: certificatefilterData?.certification?.yesDescription,
      noDescription: certificatefilterData?.certification?.noDescription,
      commonDescription:
        certificatefilterData?.certification?.commonDescription,
      title: certificatefilterData?.certification?.title,
      authorisedBy: certificatefilterData?.certification?.authorisedBy
        ? certificatefilterData?.certification?.authorisedBy
        : declarationData?.authorisedBy,
      designation: certificatefilterData?.certification?.designation
        ? certificatefilterData?.certification?.designation
        : declarationData?.designation,
      signature: certificatefilterData?.certification?.signature
        ? certificatefilterData?.certification?.signature
        : true,
      certificateLogo: certificatefilterData?.certificateLogo
        ? certificatefilterData?.certificateLogo
        : declarationData?.certificateLogo,
    });

    if (certificateData.certificateLogo) {
      axios
        .post(
          baseUrl + "/tenants/media/download",
          {
            tenantNumber: user.tenantNumber,
            filename: certificateData.certificateLogo,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
          }
        )
        .then((response) => {
          const imageBlob = new Blob([response.data], { type: "image/png" });
          const URL = window.URL || window.webkitURL;
          setCertificateLogo(URL.createObjectURL(imageBlob));
        });
    }
    if (user.companyLogo) {
      axios
        .post(
          baseUrl + "/tenants/media/download",
          {
            tenantNumber: user.tenantNumber,
            filename: user.companyLogo,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
          }
        )
        .then((response) => {
          const imageBlob = new Blob([response.data], { type: "image/png" });
          const URL = window.URL || window.webkitURL;
          setTenantLogo(URL.createObjectURL(imageBlob));
        });
    }
    if (user.declaration?.certificateLogo) {
      axios
        .post(
          baseUrl + "/tenants/media/download",
          {
            tenantNumber: user.tenantNumber,
            filename: user.declaration?.certificateLogo,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
          }
        )
        .then((response) => {
          const imageBlob = new Blob([response.data], { type: "image/png" });
          const URL = window.URL || window.webkitURL;
          setDeclarationLogo(URL.createObjectURL(imageBlob));
        });
    }
    setChildrenDrawer(true);
  };

  const onDownload = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: 3,
    });

    doc.addFileToVFS("WaterBrush-Regular-normal.ttf", myfont);
    doc.addFont("WaterBrush-Regular-normal.ttf", "myfont", "normal");
    doc.setFont("myfont");
    doc.addFileToVFS("Rubik-Regular-normal.ttf", Rubik);
    doc.addFont("Rubik-Regular-normal.ttf", "Rubik", "normal");
    doc.setFont("Rubik");
    doc.addFileToVFS("RubikBold-normal.ttf", RubikBold);
    doc.addFont("RubikBold-normal.ttf", "Rubik", "bold");
    doc.setFont("RubikBold");

    let tableHeaders = [];
    let tableContent = [];

    setPageHeight(pdfRef?.current.offsetHeight);

    const pdf = pdfRef?.current;

    const descriptionElement = pdf.querySelector("#description");
    const removeStyle = descriptionElement.querySelectorAll("[style]");

    removeStyle.forEach((element: any) => {
      element.removeAttribute("style");
    });

    const list = descriptionElement.querySelectorAll("ul, ol");

    list.forEach((e: any) => {
      e.style.paddingLeft = "20px";
      e.style.marginBottom = "10px";
    });

    const tableContainer = pdf.querySelector("#tableContainer");
    setContentHeight(tableContainer.offsetHeight);

    if (
      pdfRef?.current.offsetHeight > 800 ||
      tableContainer.offsetHeight > 350
    ) {
      pdf.removeChild(tableContainer);
      tableHeaders = Array.from(tableContainer.querySelectorAll("th")).map(
        (h: any) => h.textContent
      );

      const row = Array.from(tableContainer.querySelectorAll("tr"));
      tableContent = row
        .map((row: any) =>
          Array.from(row.querySelectorAll("td")).map((v: any) => v.textContent)
        )
        .filter((e) => e.length > 0);

      doc.addPage();
      autoTable(doc, {
        theme: "grid",
        margin: { top: 20, horizontal: 10 },
        pageBreak: "auto",
        rowPageBreak: "avoid",
        // avoidPageSplit: true,
        startY: 30,
        styles: {
          fontSize: 10,
          cellPadding: 5,
          lineWidth: 1.2,
          halign: "center",
        },
        headStyles: { fillColor: user.primaryColor },
        head: [tableHeaders],
        body: tableContent,
      });
    }

    doc.html(pdf, {
      callback: (doc) => {
        doc.save(`${user.companyName}-${regulationName}.pdf`);
        const arrayBuffer = doc.output("arraybuffer");
        const blob = new Blob([arrayBuffer], { type: "application/pdf" });
        dispatch(
          uploadDeclaration({
            file: blob,
            filename: `${user.companyName}-${regulationName}.pdf`,
          })
        ).then((response: any) => {
          const declaration = tableData.map((item: any) => ({
            exportType: "Declaration",
            path: item._id,
            exportTypeData: {
              regulationNumber: regulationName,
              type: "Bulk",
            },
            status: item.status,
            filename: response.filename,
            updatedBy: item.forceEdited ? "User" : "",
          }));
          dispatch(createDeclarationCertificate({ declaration }));
        });
        onClose();
      },
      margin: [10, 0, 10, 0],
      // font: 12,
      image: {
        type: "webp",
        quality: 90,
      },
      html2canvas: {
        width: 170,
      },
      // fontFaces: [{
      //   family: "Helvetica",
      //   weight: "100"
      // }, {
      //   family: "Courier",
      //   weight: "100"
      // }],
      x: 15,
      y: 10,
    });
  };

  const headerStyle: React.CSSProperties = {
    padding: "8px",
    textAlign: "center",
    border: "1px solid black",
    color: "white",
    fontFamily: "Rubik, sans-serif",
  };

  const bodyStyle: React.CSSProperties = {
    padding: "8px",
    textAlign: "center",
    border: "1px solid black",
    fontFamily: "Rubik, sans-serif",
  };

  useEffect(() => {
    if (open) {
      fetchRegulations("");
      fetchProductAnalytics();
    }
  }, [open]);

  return (
    <>
      <Button
        type="primary"
        onClick={showDrawer}
        title="Declaration"
        icon={<FileTextOutlined />}
        data-testid="declaration-button"
      >
        Declaration
      </Button>
      <Drawer
        title="Declaration"
        onClose={onClose}
        width={720}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-declaration-button">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              type="primary"
              disabled={!(certificate || exemption || substance || scipData)}
              data-testid="create-declaration-button"
              loading={loading}
            >
              Create Declaration
            </Button>
          </Space>
        }
      >
        {loading ? (
          <Spin
            spinning={loading}
            size="large"
            tip="Please wait preparing the data ... "
          >
            {chart}
          </Spin>
        ) : (
          <>
            <Form form={form} layout="vertical" autoComplete="off">
              <Row gutter={24}>
                <Col span={12} sm={12} md={12}>
                  <Form.Item
                    name="number"
                    label="Product Number"
                    initialValue={props.products.map((e: any) => e.number)}
                  >
                    <Select
                      mode="multiple"
                      disabled
                      dropdownStyle={{ display: "none" }}
                      maxTagCount="responsive"
                      maxTagPlaceholder={(props: any) => (
                        <Tooltip
                          title={props.map((e: any) => e.value).join(", ")}
                        >
                          <span>
                            {"+"}
                            {props?.length}
                            {""}
                          </span>
                        </Tooltip>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} sm={12} md={12}>
                  <Form.Item
                    name="regulations"
                    label="Regulations Type"
                    rules={[
                      {
                        required: true,
                        message: "Please select regulation Type!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Regulations"
                      options={regulationOptions}
                      // onSearch={(text) => fetchRegulations(text)}
                      onChange={handleRegulations}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Drawer
              title="Declaration Template"
              width={500}
              closable={false}
              onClose={onChildrenDrawerClose}
              open={childrenDrawer}
              extra={
                <Space>
                  <Button
                    onClick={onChildrenDrawerClose}
                    data-testid="cancel-download-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={onDownload}
                    type="primary"
                    data-testid="download-declaration-button"
                  >
                    Download Template
                  </Button>
                </Space>
              }
            >
              {certificate && (
                <div>
                  <Row>
                    <Col ref={pdfRef} style={{ width: "420px" }}>
                      <Row
                        justify="space-between"
                        align="middle"
                        style={{
                          background: user.declaration?.backgroundColor,
                          color: user?.declaration?.fontColor,
                        }}
                      >
                        <Col
                          style={{
                            width: "210px",
                            marginTop: "10px",
                            marginLeft: "1rem",
                          }}
                        >
                          <h2
                            style={{
                              fontFamily: "RubikBold, sans-serif",
                              fontSize: "14px",
                            }}
                          >
                            {user.declaration?.companyName
                              ? user.declaration?.companyName
                              : user.companyName}
                          </h2>
                          <p
                            style={{
                              fontFamily: "RubikBold, sans-serif",
                              fontSize: "8px",
                            }}
                          >
                            {user.declaration?.addressLine1
                              ? user.declaration?.addressLine1 + ","
                              : ""}
                            {user.declaration?.addressLine2
                              ? user.declaration?.addressLine2 + ","
                              : ""}
                            {user.declaration?.state
                              ? user.declaration?.state + ","
                              : ""}
                            {user.declaration?.country
                              ? user.declaration?.country + ","
                              : ""}
                            {user.declaration?.pincode
                              ? user.declaration?.pincode + ","
                              : ""}
                            <br></br>
                            {user?.declaration?.companyPhoneNumber
                              ? " Ph:" + user?.declaration?.companyPhoneNumber
                              : ""}
                          </p>
                        </Col>
                        <Col
                          style={{ textAlign: "right", marginRight: "1rem" }}
                        >
                          {certificateLogo ? (
                            <img
                              src={certificateLogo ? certificateLogo : logo}
                              alt="Logo"
                              height={30}
                            />
                          ) : declarationLogo ? (
                            <img
                              src={declarationLogo ? declarationLogo : logo}
                              alt="Logo"
                              height={30}
                            />
                          ) : tenantLogo ? (
                            <img
                              src={tenantLogo ? tenantLogo : logo}
                              alt="Logo"
                              height={30}
                            />
                          ) : (
                            <img src={logoIcon} alt="Logo" height={30} />
                          )}
                          <p
                            style={{
                              fontFamily: "RubikBold, sans-serif",
                              fontSize: "8px",
                            }}
                          >
                            {user.declaration?.website
                              ? user.declaration?.website
                              : ""}{" "}
                            <br></br>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ width: "420px" }}>
                          <p
                            style={{
                              textAlign: "right",
                              fontFamily: "Rubik, sans-serif",
                              fontSize: "10px",
                              marginTop: "2px",
                            }}
                          >
                            Date: {currentDate}
                          </p>
                        </Col>
                      </Row>
                      <h3
                        style={{
                          fontFamily: "RubikBold, sans-serif",
                          textAlign: "center",
                          fontSize: "12px",
                          marginBottom: "15px",
                        }}
                      >
                        {certificateData.title}
                      </h3>
                      <p
                        id="description"
                        style={{
                          fontFamily: "Rubik, RubikBold , sans-serif",
                          fontSize: "8px",
                          marginBottom: "15px",
                          lineHeight: "1.5",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: certificateData.commonDescription,
                          }}
                        />
                      </p>
                      <div id="tableContainer">
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            marginTop: "20px",
                            fontSize: "8px",
                          }}
                        >
                          <thead>
                            <tr
                              style={{
                                background: "#151B54",
                                borderLeft: "1px solid black",
                              }}
                            >
                              <th style={headerStyle}>Product Number</th>
                              <th style={headerStyle}>Product Name</th>
                              <th style={headerStyle}>
                                {regulationName} Compliant Status
                              </th>
                              {(() => {
                                if (
                                  regulationNumber === "eu_rohs" &&
                                  exemption
                                ) {
                                  if (
                                    tableData?.some(
                                      (item: any) =>
                                        !item.forceEdited &&
                                        item.status ===
                                          "Compliant with Exemption"
                                    )
                                  ) {
                                    return (
                                      <th style={headerStyle}>Exemptions</th>
                                    );
                                  } else if (
                                    tableData?.some(
                                      (item: any) =>
                                        item.forceEdited &&
                                        item.status ===
                                          "Compliant with Exemption"
                                    )
                                  ) {
                                    return (
                                      <th style={headerStyle}>Exemptions</th>
                                    );
                                  }
                                } else {
                                  return;
                                }
                              })()}
                              {(() => {
                                if (
                                  regulationNumber !== "eu_rohs" &&
                                  substance
                                ) {
                                  if (
                                    tableData?.some(
                                      (item: any) =>
                                        !item.forceEdited &&
                                        item.status === "Non-Compliant"
                                    )
                                  ) {
                                    return (
                                      <th style={headerStyle}>Substances</th>
                                    );
                                  } else if (
                                    tableData?.some(
                                      (item: any) =>
                                        item.forceEdited &&
                                        item.status === "Non-Compliant"
                                    )
                                  ) {
                                    return (
                                      <th style={headerStyle}>Substances</th>
                                    );
                                  }
                                } else {
                                  return;
                                }
                              })()}
                              {(() => {
                                if (
                                  permissions?.read?.includes("scip") &&
                                  regulationNumber === "eu_reach" &&
                                  scipData
                                ) {
                                  if (
                                    tableData?.some(
                                      (item: any) =>
                                        item.status === "Non-Compliant" &&
                                        item.scipNumber
                                    )
                                  ) {
                                    return <th style={headerStyle}>SCIP #</th>;
                                  } else if (
                                    tableData?.some(
                                      (item: any) =>
                                        item.status === "Non-Compliant" &&
                                        !item.scipNumber
                                    )
                                  ) {
                                    return <th style={headerStyle}>SCIP #</th>;
                                  }
                                } else {
                                  return;
                                }
                              })()}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData?.map((item: any, index: any) => (
                              <tr
                                key={item._id}
                                style={{ borderBottom: "1px solid #ddd" }}
                              >
                                <td style={bodyStyle}>{item.number}</td>
                                <td style={bodyStyle}>{item.name}</td>
                                <td
                                  style={bodyStyle}
                                  onDoubleClick={() =>
                                    handleDoubleClick("status", index)
                                  }
                                >
                                  {item.editStatus ? (
                                    <Select
                                      value={item.status}
                                      onChange={(value) =>
                                        handleStatusChange(index, value)
                                      }
                                      style={{
                                        width: "100%",
                                        fontSize: "8px",
                                      }}
                                      onBlur={() => handleBlur("status", index)}
                                      autoFocus
                                      options={[
                                        {
                                          value: "Compliant",
                                          label: "Compliant",
                                        },
                                        {
                                          value: "Non-Compliant",
                                          label: "Non-Compliant",
                                        },
                                        {
                                          value: "Compliant with Exemption",
                                          label: "Compliant with Exemption",
                                          disabled:
                                            regulationNumber !== "eu_rohs",
                                        },
                                        {
                                          value: "Un-determined/ Under review",
                                          label: "Un-determined/ Under review",
                                        },
                                      ]}
                                    />
                                  ) : (
                                    item.status
                                  )}
                                </td>
                                {(() => {
                                  if (
                                    regulationNumber === "eu_rohs" &&
                                    exemption &&
                                    item.status === "Compliant with Exemption"
                                  ) {
                                    if (!item.forceEdited) {
                                      return (
                                        <td style={bodyStyle}>
                                          {item &&
                                          item.regulations.find(
                                            (e: any) =>
                                              e.number === regulationNumber
                                          )?.exemptions.length > 0
                                            ? item.regulations
                                                .find(
                                                  (e: any) =>
                                                    e.number ===
                                                    regulationNumber
                                                )
                                                ?.exemptions.toString()
                                            : "-"}
                                        </td>
                                      );
                                    } else if (item.forceEdited) {
                                      return (
                                        <td
                                          style={bodyStyle}
                                          onDoubleClick={() =>
                                            handleDoubleClick(
                                              "exemption",
                                              index
                                            )
                                          }
                                        >
                                          {item.forceEditExemptions ? (
                                            <Select
                                              options={searchExemptions.map(
                                                (option) => ({
                                                  ...option,
                                                  label: (
                                                    <Text>
                                                      {option.exemption}
                                                    </Text>
                                                  ),
                                                })
                                              )}
                                              style={{
                                                width: "100%",
                                                fontSize: "8px",
                                              }}
                                              mode="multiple"
                                              onChange={(value: any) => {
                                                handleExemptionChange(
                                                  index,
                                                  value
                                                );
                                              }}
                                              onSearch={(value) =>
                                                handleSearchExemptions(
                                                  value,
                                                  regulationNumber
                                                )
                                              }
                                              onBlur={() =>
                                                handleBlur("exemption", index)
                                              }
                                              placeholder="Type to search Exemptions"
                                              allowClear
                                              showSearch
                                            />
                                          ) : (
                                            item.exemptions?.toString()
                                          )}
                                        </td>
                                      );
                                    }
                                  } else if (
                                    regulationNumber === "eu_rohs" &&
                                    exemption &&
                                    item.status !== "Compliant with Exemption"
                                  ) {
                                    return <td style={bodyStyle}>-</td>;
                                  } else {
                                    return null;
                                  }
                                })()}
                                {(() => {
                                  if (
                                    regulationNumber !== "eu_rohs" &&
                                    substance &&
                                    item.status === "Non-Compliant"
                                  ) {
                                    if (!item.forceEdited) {
                                      return (
                                        <td style={bodyStyle}>
                                          {item &&
                                          item.regulations.find(
                                            (e: any) =>
                                              e.number === regulationNumber
                                          )?.substances.length > 0
                                            ? item.regulations
                                                .find(
                                                  (e: any) =>
                                                    e.number ===
                                                    regulationNumber
                                                )
                                                ?.substances.toString()
                                            : "-"}
                                        </td>
                                      );
                                    } else if (item.forceEdited) {
                                      return (
                                        <td
                                          style={{
                                            ...bodyStyle,
                                            maxWidth: "200px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                          onDoubleClick={() =>
                                            handleDoubleClick(
                                              "substance",
                                              index
                                            )
                                          }
                                        >
                                          {item.forceEditSubstances ? (
                                            <Select
                                              options={searchSubstances.map(
                                                (option) => ({
                                                  ...option,
                                                  label: (
                                                    <Text
                                                      style={{
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                      }}
                                                    >
                                                      {option.substance}
                                                      <br></br>
                                                      <small>
                                                        {option.casNumber}
                                                      </small>
                                                    </Text>
                                                  ),
                                                })
                                              )}
                                              style={{
                                                width: "100%",
                                                fontSize: "8px",
                                              }}
                                              mode="multiple"
                                              onChange={(value: any) => {
                                                handleSubstanceChange(
                                                  index,
                                                  value
                                                );
                                              }}
                                              onSearch={(value) =>
                                                handleSearchSubstances(
                                                  value,
                                                  regulationNumber
                                                )
                                              }
                                              onBlur={() =>
                                                handleBlur("substance", index)
                                              }
                                              placeholder="Type to search Substance of Concern"
                                              allowClear
                                              showSearch
                                            />
                                          ) : (
                                            item.substances?.toString()
                                          )}
                                        </td>
                                      );
                                    }
                                  } else if (
                                    regulationNumber !== "eu_rohs" &&
                                    substance &&
                                    item.status !== "Non-Compliant"
                                  ) {
                                    return <td style={bodyStyle}>-</td>;
                                  } else {
                                    return;
                                  }
                                })()}
                                {(() => {
                                  if (
                                    permissions?.read?.includes("scip") &&
                                    regulationNumber === "eu_reach"
                                  ) {
                                    if (
                                      scipData &&
                                      item.scipNumber &&
                                      item.status === "Non-Compliant"
                                    ) {
                                      return (
                                        <td style={bodyStyle}>
                                          {item?.scipNumber
                                            ? item?.scipNumber
                                            : "-"}
                                        </td>
                                      );
                                    } else if (
                                      scipData &&
                                      item.status !== "Non-Compliant"
                                    ) {
                                      return <td style={bodyStyle}>-</td>;
                                    } else if (
                                      item.status === "Non-Compliant" &&
                                      !item.scipNumber &&
                                      scipData
                                    ) {
                                      return <td style={bodyStyle}>-</td>;
                                    } else if (!scipData && !item.scipNumber) {
                                      return null;
                                    }
                                  } else {
                                    return null;
                                  }
                                })()}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {(pageHeight > 800 || contentHeight > 350) && (
                        <p
                          style={{
                            fontFamily: "Rubik, sans-serif",
                            fontSize: "8px",
                            marginBottom: "15px",
                          }}
                        >
                          Note: Please refer the table listed below.
                        </p>
                      )}
                      <p
                        style={{
                          fontFamily: "Rubik, sans-serif",
                          fontSize: "8px",
                          marginTop: "50px",
                          marginBottom: "0px",
                        }}
                      >
                        Authorised Representative
                      </p>
                      {Boolean(certificateData?.signature) && (
                        <p
                          style={{
                            fontFamily: "myFont, Water Brush",
                            fontSize: "18px",
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          {certificateData.authorisedBy
                            ? certificateData.authorisedBy
                            : user.declaration?.authorisedBy}
                        </p>
                      )}
                      <p
                        style={{
                          fontFamily: "Rubik, sans-serif",
                          fontSize: "8px",
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        {certificateData.authorisedBy
                          ? certificateData.authorisedBy
                          : user.declaration?.authorisedBy}
                      </p>
                      {certificateData.designation && (
                        <p
                          style={{
                            fontFamily: "Rubik, sans-serif",
                            fontSize: "8px",
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          {certificateData.designation
                            ? certificateData.designation
                            : user.declaration?.designation}
                        </p>
                      )}
                      {user.declaration?.emailAddress && (
                        <p
                          style={{
                            fontFamily: "Rubik, sans-serif",
                            fontSize: "8px",
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          {user.declaration?.emailAddress}
                        </p>
                      )}
                      {user.declaration?.phoneNumber && (
                        <p
                          style={{
                            fontFamily: "Rubik, sans-serif",
                            fontSize: "8px",
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          {user.declaration?.phoneNumber}
                        </p>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </Drawer>
          </>
        )}
      </Drawer>
    </>
  );
};

export default CreateBulkDeclarationModal;
