import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
  ExclamationCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Button,
  Drawer,
  Select,
  Typography,
  Tabs,
  Checkbox,
  Card,
  Collapse,
  InputNumber,
  Spin,
} from "antd";
import type { TabsProps, CollapseProps, CheckboxProps } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import {
  listScipSubstances,
  createScipArticle,
  readScipComponent,
} from "../../redux/actions/scipAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../utils/localStore";
import FileUploader from "../layouts/FileUploader";
import {
  PrimaryArticleIdentifierTypeList,
  EUProductionFlagList,
  ConcentrationRangeList,
  MixtureCategoryEUPCSList,
  MaterialCategoriesList,
  unit,
  densityUnit,
  weightUnit,
  volumeUnit,
  color,
} from "../../utils/constants/scip";
import { listRegulationSubtances } from "../../redux/actions/regulationsAction";
import { createSupplierScip } from "../../redux/actions/supplierPortalAction";
import { getPhrasesByGroup } from "../../utils/constants/indexedDb";

const { Title, Text } = Typography;
const { TextArea } = Input;

const ScipForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: any;
  campaignId?: string;
  supplierName?: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [regulationLoading, setRegulationLoading] = useState(false);
  const [datasetLoading, setDatasetLoading] = useState(false);
  const [scipComponentArticle, setScipComponentArticle] = useState<any>();
  const [substancesLoading, setSubstancesLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { tenantNumber } = getLocalStorage("user");
  const [editScip, setEditScip] = useState<boolean>(false);
  const [searchSubstances, setSearchSubstances] = useState<any[]>([]);
  const [articleCategory, setArticleCategory] = useState<any[]>([]);
  const [identifiersIcon, setIdentifiersIcon] = useState<string | boolean>("");
  const [instructionIcon, setInstructionIcon] = useState<string | boolean>("");
  const [concernIcon, setConcernIcon] = useState<string | boolean>("");
  const [primaryArticleIdentifierType, setPrimaryArticleIdentifierType] =
    useState<string>("");
  const [checked, setChecked] = useState(false);
  const [versionModified, setVersionModified] = useState(false);
  const regulations = getRegulationsFromLocalStorage() ?? [];

  const configuration = regulations
    .find((e: any) => e.regulationNumber === "eu_reach")
    ?.configuration.find((v: any) => v.value === "complianceOutputs.version");

  const onEditClick = () => {
    setEditScip(true);
    setCurrentStep(0);
  };

  const characteristicsItem: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <Text>Characteristics (Optional) </Text>
        </>
      ),
      children: (
        <>
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "Height"]}
                label="Height"
                rules={[]}
              >
                <InputNumber
                  style={{ width: 324 }}
                  min={0}
                  disabled={!editScip}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "HeightUnit"]}
                label="Height Unit"
                rules={[]}
              >
                <Select
                  options={unit.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                  disabled={!editScip}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "Weight"]}
                label="Weight"
                rules={[]}
              >
                <InputNumber
                  style={{ width: 324 }}
                  min={0}
                  disabled={!editScip}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "WeightUnit"]}
                label="Weight Unit"
                rules={[]}
              >
                <Select
                  options={weightUnit.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                  disabled={!editScip}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "Volume"]}
                label="Volume"
                rules={[]}
              >
                <InputNumber
                  style={{ width: 324 }}
                  min={0}
                  disabled={!editScip}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "VolumeUnit"]}
                label="Volume Unit"
                rules={[]}
              >
                <Select
                  options={volumeUnit.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                  disabled={!editScip}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "Density"]}
                label="Density"
                rules={[]}
              >
                <InputNumber
                  style={{ width: 324 }}
                  min={0}
                  disabled={!editScip}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "DensityUnit"]}
                label="Density Unit"
                rules={[]}
              >
                <Select
                  options={densityUnit.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                  disabled={!editScip}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "Color"]}
                label="Color"
                rules={[]}
              >
                <Select
                  options={color.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                  mode="multiple"
                  disabled={!editScip}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name={["Characteristics", "pictureImage"]}
                label="Picture Image Upload"
                rules={[]}
              >
                <FileUploader
                  postUrl="scip/document/upload"
                  tenantNumber={tenantNumber}
                  setFormField={(value: string) =>
                    form.setFieldValue(
                      ["Characteristics", "pictureImage"],
                      value
                    )
                  }
                  disabled={!editScip}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const onChangeCheck: CheckboxProps["onChange"] = (e) => {
    setChecked(e.target.checked);
    form.setFieldValue("SafeUseInstructions", []);
  };

  const items: TabsProps["items"] = [
    {
      key: "0",
      label: (
        <>
          Identifiers
          <span style={{ marginLeft: "8px" }}>
            {identifiersIcon === true ? (
              <ExclamationCircleFilled
                style={{ color: "#F5222D", fontSize: "16px" }}
              />
            ) : identifiersIcon === false ? (
              <CheckCircleFilled
                style={{ color: "#52C41A", fontSize: "16px" }}
              />
            ) : (
              ""
            )}
          </span>
        </>
      ),
      children: (
        <>
          {/* <Title level={5}>Identifiers</Title> */}
          {/* <Divider /> */}
          <Form.List name="manufacturer">
            {() => (
              <>
                <Row gutter={24}>
                  <Col span={12} sm={12} md={12}>
                    <Form.Item name="name" label="Manufacturer Name">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12} sm={12} md={12}>
                    <Form.Item
                      name="itemNumber"
                      label="Manufacturer Item Number"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="version" label="EU REACH SVHC Version">
                <Select
                  placeholder={`Select ${configuration.text}`}
                  options={configuration.dataValue
                    .split(",")
                    .map((value: string) => ({ label: value, value }))}
                  disabled={!editScip || props.type === "supplier"}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="ArticleName"
                label="Article Name"
                rules={[
                  {
                    required: true,
                    message: "Please input Article Name!",
                  },
                ]}
              >
                <Input disabled={!editScip} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="PrimaryArticleIdentifierType"
                label="Article Identifier (Type)"
                rules={[
                  {
                    required: true,
                    message: "Please Select Article Identifier (Type)!",
                  },
                ]}
              >
                <Select
                  options={PrimaryArticleIdentifierTypeList.map((option) => ({
                    label: <Text>{option.text}</Text>,
                    value: option.code,
                  }))}
                  disabled={!editScip}
                  onChange={(value) => {
                    setPrimaryArticleIdentifierType(value);
                    if (value !== "66306") {
                      form.setFieldValue("PrimaryArticleIdentifierValue", "");
                    } else {
                      form.setFieldValue(
                        "PrimaryArticleIdentifierValue",
                        scipComponentArticle?.PrimaryArticleIdentifierValue
                          ? scipComponentArticle?.PrimaryArticleIdentifierValue
                          : scipComponentArticle?.manufacturer?.itemNumber
                      );
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="PrimaryArticleIdentifierValue"
                label="Article Identifier (Value)"
                rules={[
                  {
                    required: true,
                    message: "Please Select Article Identifier (Value)!",
                  },
                ]}
              >
                <Input disabled={!editScip} />
              </Form.Item>
            </Col>
          </Row>
          {/* <Title level={5}>Categorisation</Title> */}
          {/* <Divider /> */}
          {primaryArticleIdentifierType !== "66297" && (
            <Row gutter={24}>
              <Col span={12} sm={12} md={12}>
                <Form.Item
                  name="ArticleCategory"
                  label="Article Category"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Article Category!",
                    },
                  ]}
                >
                  <Select
                    options={articleCategory?.map((option) => ({
                      ...option,
                      label: (
                        <Text
                          ellipsis={{
                            tooltip: option.text,
                          }}
                        >
                          {option.text}
                        </Text>
                      ),
                      value: option.code,
                    }))}
                    mode="multiple"
                    // onChange={handleSelectArticleCategory}
                    // onSearch={handleSearchArticleCategory}
                    disabled={!editScip}
                  />
                </Form.Item>
              </Col>
              <Col span={12} sm={12} md={12}>
                <Form.Item
                  name="EUProductionFlag"
                  label="Production in European Union"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Production in European Union!",
                    },
                  ]}
                >
                  <Select
                    options={EUProductionFlagList.map((option) => ({
                      label: <Text>{option.text}</Text>,
                      value: option.code,
                    }))}
                    disabled={!editScip}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Col>
            {primaryArticleIdentifierType !== "66297" && (
              <Collapse items={characteristicsItem} bordered={false} />
            )}
          </Col>
        </>
      ),
    },
  ];

  const tabItems = [
    {
      key: "1",
      label: (
        <>
          Safe Use Instructions
          <span style={{ marginLeft: "8px" }}>
            {instructionIcon === true ? (
              <ExclamationCircleFilled
                style={{ color: "#F5222D", fontSize: "16px" }}
              />
            ) : instructionIcon === false ? (
              <CheckCircleFilled
                style={{ color: "#52C41A", fontSize: "16px" }}
              />
            ) : (
              ""
            )}
          </span>
        </>
      ),
      children: (
        <>
          <Text type="secondary">
            Safe use instructions <span style={{ color: "red" }}>*</span>
          </Text>
          <Row gutter={24} style={{ marginTop: "1rem" }}>
            <Col span={24} sm={24} md={24}>
              <Form.Item
                name="NoSafeUseInstructions"
                valuePropName="checked"
                rules={[
                  {
                    validator(_, value) {
                      if (
                        !value &&
                        !form.getFieldValue("SafeUseInstructions")
                      ) {
                        return Promise.reject(
                          new Error(
                            "Either the checkbox must be checked or the Add instrutions must be filled."
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Checkbox disabled={!editScip} onChange={onChangeCheck}>
                  The identification of the Candidate List substance is
                  sufficient to allow safe use of the article throughout the
                  whole life cycle including service life, disassembly and
                  waste/recycling stage
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "1rem" }}>
            <Col span={24} sm={24} md={24}>
              <Form.List
                name="SafeUseInstructions"
                rules={[
                  {
                    validator: async (_, value) => {
                      if (
                        value?.length === 0 &&
                        !form.getFieldValue("NoSafeUseInstructions")
                      ) {
                        return Promise.reject(
                          new Error(
                            "Either the instructions field must be filled or the checkbox must be checked."
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <>
                        <Row gutter={[24, 24]}>
                          <Col span={23} sm={23} md={23}>
                            <Form.Item key={key} style={{ marginBottom: 8 }}>
                              <Form.Item
                                name={[name]}
                                rules={[
                                  {
                                    validator: async (_, value) => {
                                      if (!value) {
                                        return Promise.reject(
                                          new Error(
                                            "Instructions cannot be empty"
                                          )
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Safe Use Instructions"
                                  disabled={!editScip || checked}
                                />
                              </Form.Item>
                            </Form.Item>
                          </Col>
                          <Col span={1} sm={1} md={1}>
                            {editScip && (
                              <DeleteOutlined onClick={() => remove(name)} />
                            )}
                          </Col>
                        </Row>
                      </>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        disabled={!editScip || checked}
                      >
                        Add Instruction
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </>
      ),
      disabled: editScip && currentStep < 1,
    },
    {
      key: "2",
      label: (
        <>
          Concern Elements
          <span style={{ marginLeft: "8px" }}>
            {concernIcon === true ? (
              <ExclamationCircleFilled
                style={{ color: "#F5222D", fontSize: "16px" }}
              />
            ) : concernIcon === false ? (
              <CheckCircleFilled
                style={{ color: "#52C41A", fontSize: "16px" }}
              />
            ) : (
              ""
            )}
          </span>
        </>
      ),
      children: (
        <>
          <Row gutter={24} style={{ marginTop: "1rem" }}>
            <Col span={24} sm={24} md={24}>
              <Form.List
                name="ConcernElements"
                rules={[
                  {
                    validator: async (_, ConcernElements) => {
                      if (!ConcernElements || ConcernElements?.length < 1) {
                        return Promise.reject(
                          new Error("At least 1 Concern Elements required")
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <Space
                    size="middle"
                    direction="vertical"
                    style={{ display: "flex" }}
                  >
                    {fields.map((field) => (
                      <>
                        <Card
                          //type="inner"
                          size="small"
                          title={` ${field.name + 1}`}
                          key={field.key}
                          extra={
                            editScip && (
                              <DeleteOutlined
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            )
                          }
                          styles={{
                            header: {
                              color: "black",
                            },
                          }}
                        >
                          <Row gutter={[24, 24]}>
                            <Col span={8} sm={8} md={8}>
                              <Form.Item
                                name={[
                                  field.name,
                                  "CandidateListSubstanceLink",
                                ]}
                                label="Candidate List Substance"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Substance!",
                                  },
                                ]}
                              >
                                <Select
                                  options={searchSubstances.map((option) => ({
                                    ...option,
                                    label: <Text>{option.name}</Text>,
                                    // value: option.key,
                                  }))}
                                  onSearch={handleSearchSubstances}
                                  onChange={(value, option) => {
                                    form.setFieldValue(
                                      [
                                        "ConcernElements",
                                        field.name,
                                        "CasNumber",
                                      ],
                                      option.casNumber
                                    );
                                    form.setFieldValue(
                                      [
                                        "ConcernElements",
                                        field.name,
                                        "EcNumber",
                                      ],
                                      option.numberInInventory
                                    );
                                  }}
                                  disabled={!editScip}
                                  showSearch
                                  allowClear
                                  loading={substancesLoading}
                                  filterOption={false}
                                  // notFoundContent={
                                  //   substancesLoading ? (
                                  //     <Spin size="small" />
                                  //   ) : null
                                  // }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8} sm={8} md={8}>
                              <Form.Item
                                name={[field.name, "CasNumber"]}
                                label="CAS Number"
                                rules={[
                                  {
                                    required: true,
                                    message: "CAS number is required!",
                                  },
                                ]}
                              >
                                <Input disabled={!editScip} />
                              </Form.Item>
                            </Col>
                            <Col span={8} sm={8} md={8}>
                              <Form.Item
                                name={[field.name, "EcNumber"]}
                                label="EC Number"
                                rules={[
                                  {
                                    required: true,
                                    message: "EC number is required!",
                                  },
                                ]}
                              >
                                <Input disabled={!editScip} />
                              </Form.Item>
                            </Col>
                            <Col span={8} sm={8} md={8}>
                              <Form.Item
                                name={[field.name, "ConcentrationRange"]}
                                label="Concentration Range"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please Select Concentration Range!",
                                  },
                                ]}
                              >
                                <Select
                                  options={ConcentrationRangeList.map(
                                    (option) => ({
                                      label: (
                                        <Text
                                          ellipsis={{
                                            tooltip: option.text,
                                          }}
                                        >
                                          {option.text}
                                        </Text>
                                      ),
                                      value: option.code,
                                    })
                                  )}
                                  disabled={!editScip}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8} sm={8} md={8}>
                              <Form.Item
                                name={[field.name, "MaterialCategories"]}
                                label="Material Categories"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please Select Material Categories!",
                                  },
                                ]}
                              >
                                <Select
                                  options={MaterialCategoriesList.map(
                                    (option) => ({
                                      label: (
                                        <Text
                                          ellipsis={{
                                            tooltip: option.text,
                                          }}
                                        >
                                          {option.text}
                                        </Text>
                                      ),
                                      value: option.code,
                                    })
                                  )}
                                  mode="multiple"
                                  disabled={!editScip}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8} sm={8} md={8}>
                              <Form.Item
                                name={[field.name, "MixtureCategoryEUPCS"]}
                                label="Mixture Category"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Mixture Category!",
                                  },
                                ]}
                              >
                                <Select
                                  options={MixtureCategoryEUPCSList.map(
                                    (option) => ({
                                      label: (
                                        <Text
                                          ellipsis={{
                                            tooltip: option.description,
                                          }}
                                        >
                                          {option.description}
                                        </Text>
                                      ),
                                      value: option.code,
                                    })
                                  )}
                                  mode="multiple"
                                  disabled={!editScip}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          add();
                        }}
                        block
                        icon={<PlusOutlined />}
                        disabled={!editScip}
                      >
                        Add Concern Elements
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </Space>
                )}
              </Form.List>
            </Col>
          </Row>
        </>
      ),
      disabled: editScip && currentStep < 2,
    },
  ];

  if (primaryArticleIdentifierType !== "66297") {
    items.push(...tabItems);
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
    setEditScip(false);
    setPrimaryArticleIdentifierType("");
  };

  const handleTabChange = (key: string) => {
    setCurrentStep(parseInt(key));
  };

  const handleSearchSubstances = (search?: any) => {
    setSubstancesLoading(true);
    dispatch(
      listScipSubstances({
        pageNum: 1,
        pageSize: 500,
        search: search ? search : " ",
      })
    )
      .then((response: any) => {
        setSearchSubstances(
          response.substances.map((e: any) => ({
            ...e,
            value: e.key,
          }))
        );
        setSubstancesLoading(false);
      })
      .catch(() => setSubstancesLoading(false));
  };

  const fetchRegulationData = () => {
    setRegulationLoading(true);
    dispatch(
      listRegulationSubtances({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify({
          substance:
            scipComponentArticle?.compliances?.complianceOutputs?.substance?.map(
              (i: any) => i
            ) || null,
          regulationNumber: "eu_reach",
        }),
      })
    )
      .then((response) => {
        const uniqueSubstances = response.substances?.filter(
          (value: any, index: number, self: any[]) =>
            index === self.findIndex((i) => i.substance === value.substance)
        );
        const ConcernElements = uniqueSubstances
          ?.map((i: any, index: number) => {
            const data = scipComponentArticle?.ConcernElements || [{}];
            const e = data[index] || {};
            if (e) {
              return {
                CandidateListSubstanceLink: e.CandidateListSubstanceLink?.key
                  ? e.CandidateListSubstanceLink?.key
                  : i.substance,
                CasNumber: e.CandidateListSubstanceLink?.casNumber
                  ? e.CandidateListSubstanceLink?.casNumber
                  : i.casNumber || null,
                EcNumber: e.CandidateListSubstanceLink?.numberInInventory
                  ? e.CandidateListSubstanceLink?.numberInInventory
                  : i.ecNumber || null,
                ConcentrationRange: e.ConcentrationRange?.code || null,
                MaterialCategories:
                  e.MaterialCategories?.map((i: any) => i?.code) || null,
                MixtureCategoryEUPCS:
                  e.MixtureCategoryEUPCS?.map((i: any) => i?.code) || null,
              };
            } else {
              return null;
            }
          })
          .filter(Boolean);

        form.setFieldValue("ConcernElements", ConcernElements);
        setRegulationLoading(false);
      })
      .catch(() => {
        setRegulationLoading(false);
      });
  };

  const onSaveArticle = async () => {
    try {
      if (currentStep === 0) {
        await form.validateFields([
          ["ArticleName"],
          ["PrimaryArticleIdentifierType"],
          ["PrimaryArticleIdentifierValue"],
          primaryArticleIdentifierType !== "66297" && ["ArticleCategory"],
          primaryArticleIdentifierType !== "66297" && ["EUProductionFlag"],
        ]);
        setIdentifiersIcon(false);
        onFinish();
      } else if (currentStep === 1) {
        await form.validateFields([
          ["NoSafeUseInstructions"],
          ["SafeUseInstructions"],
        ]);
        const values = form.getFieldValue("SafeUseInstructions") || [];
        for (let index = 0; index <= values?.length; index++) {
          await form.validateFields([["SafeUseInstructions", index]]);
        }
        setInstructionIcon(false);
        onFinish();
      }
    } catch (error: any) {
      if (error.errorFields.length > 0) {
        const identifier = [
          "ArticleName",
          "PrimaryArticleIdentifierType",
          "PrimaryArticleIdentifierValue",
          primaryArticleIdentifierType !== "66297" && "ArticleCategory",
          primaryArticleIdentifierType !== "66297" && "EUProductionFlag",
        ];
        if (
          error.errorFields.some((e: any) =>
            identifier.some((o) => e.name.includes(o))
          )
        ) {
          setIdentifiersIcon(true);
        }

        const instructions = ["NoSafeUseInstructions", "SafeUseInstructions"];
        if (
          error.errorFields.some((e: any) =>
            instructions.some((o) => e.name.includes(o))
          )
        ) {
          setInstructionIcon(true);
        }
      }
    }
  };

  const onFinish = () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const { version, ...restValues } = values;
    const articles = {
      componentId: props.formData?.componentId
        ? props.formData?.componentId
        : props.formData?._id,
      type: "components",
      currentStep: currentStep,
      ...restValues,
      ...(versionModified && { version }),
      PrimaryArticleIdentifierType: PrimaryArticleIdentifierTypeList.find(
        (e: any) => e.code === values.PrimaryArticleIdentifierType
      ),
      EUProductionFlag: EUProductionFlagList.find(
        (e: any) => e.code === values.EUProductionFlag
      ),
      ArticleCategory: values?.ArticleCategory?.map((value: any) =>
        articleCategory.find((e) => e.code === value)
      ),
      Characteristics: {
        ...values.Characteristics,
        HeightUnit: unit.find(
          (e: any) => e.code === values.Characteristics?.HeightUnit
        ),
        WeightUnit: weightUnit.find(
          (e: any) => e.code === values.Characteristics?.WeightUnit
        ),
        VolumeUnit: volumeUnit.find(
          (e: any) => e.code === values.Characteristics?.VolumeUnit
        ),
        DensityUnit: densityUnit.find(
          (e: any) => e.code === values.Characteristics?.DensityUnit
        ),
        Color: color.find((e: any) => e.code === values.Characteristics?.Color),
      },
      ConcernElements: values?.ConcernElements?.map((e: any) => ({
        CandidateListSubstanceLink: searchSubstances.find(
          (v) =>
            v.name === e.CandidateListSubstanceLink ||
            v.key === e.CandidateListSubstanceLink
        ),
        ConcentrationRange: ConcentrationRangeList.find(
          (v) => v.code === e.ConcentrationRange
        ),
        MaterialCategories: e.MaterialCategories?.map((value: any) =>
          MaterialCategoriesList.find((v) => v.code === value)
        ),
        MixtureCategoryEUPCS: e.MixtureCategoryEUPCS?.map((value: any) =>
          MixtureCategoryEUPCSList.find((v) => v.code === value)
        ),
      })),
    };

    if (props.type === "supplier") {
      if (currentStep === 2 || primaryArticleIdentifierType === "66297") {
        dispatch(
          createSupplierScip({
            ...articles,
            sendgridCampaignId: props.campaignId,
            tenantNumber: tenantNumber,
            supplierName: props.supplierName,
          })
        ).then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
          setEditScip(false);
          setPrimaryArticleIdentifierType("");
        });
      } else {
        dispatch(
          createSupplierScip({
            ...articles,
            sendgridCampaignId: props.campaignId,
            tenantNumber: tenantNumber,
            supplierName: props.supplierName,
          })
        ).then(() => {
          setLoading(false);
          setCurrentStep(currentStep + 1);
        });
      }
    } else {
      if (currentStep === 2 || primaryArticleIdentifierType === "66297") {
        dispatch(createScipArticle(articles)).then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
          setEditScip(false);
          setPrimaryArticleIdentifierType("");
        });
      } else {
        dispatch(createScipArticle(articles)).then(() => {
          setLoading(false);
          setCurrentStep(currentStep + 1);
        });
      }
    }
  };

  const onSubmit = async () => {
    if (currentStep === 2) {
      try {
        await form.validateFields(["ConcernElements"]);
        await form.validateFields();
        setConcernIcon(false);
        onFinish();
      } catch (error: any) {
        if (error.errorFields.length > 0) {
          setConcernIcon(true);
        }
      }
    } else {
      onFinish();
    }
  };

  const fetchData = () => {
    setDatasetLoading(true);
    dispatch(
      readScipComponent(
        props?.formData?.componentId
          ? props?.formData?.componentId
          : props?.formData?._id,
        {}
      )
    ).then((response: any) => {
      setScipComponentArticle(response.scipComponentArticle);
      form.setFieldValue(
        "ArticleName",
        response.scipComponentArticle?.ArticleName
          ? response.scipComponentArticle?.ArticleName
          : response.scipComponentArticle?.specification?.description
      );
      form.setFieldValue(
        "PrimaryArticleIdentifierValue",
        response.scipComponentArticle?.PrimaryArticleIdentifierValue
          ? response.scipComponentArticle?.PrimaryArticleIdentifierValue
          : response.scipComponentArticle?.manufacturer?.itemNumber
      );
      form.setFieldValue(
        "PrimaryArticleIdentifierType",
        response.scipComponentArticle?.PrimaryArticleIdentifierType?.code
          ? response.scipComponentArticle?.PrimaryArticleIdentifierType?.code
          : PrimaryArticleIdentifierTypeList.find(
              (v: any) => v.code === "66306"
            )?.code
      );

      setPrimaryArticleIdentifierType(
        response.scipComponentArticle?.PrimaryArticleIdentifierType?.code
      );
      form.setFieldValue(
        "NoSafeUseInstructions",
        response.scipComponentArticle?.NoSafeUseInstructions
      );

      form.setFieldValue(
        "SafeUseInstructions",
        response.scipComponentArticle?.SafeUseInstructions
      );

      form.setFieldValue(
        "ArticleCategory",
        response.scipComponentArticle?.ArticleCategory?.map((e: any) => e.code)
      );
      form.setFieldValue(
        "EUProductionFlag",
        response.scipComponentArticle?.EUProductionFlag?.code
      );

      form.setFieldValue(
        "version",
        response.scipComponentArticle?.compliances?.complianceOutputs?.version
      );

      const Characteristics = {
        ...response.scipComponentArticle?.Characteristics,
        ...(response.scipComponentArticle.Characteristics?.HeightUnit && {
          HeightUnit:
            response.scipComponentArticle.Characteristics?.HeightUnit?.code,
        }),
        ...(response.scipComponentArticle.Characteristics?.WeightUnit && {
          WeightUnit:
            response.scipComponentArticle.Characteristics?.WeightUnit?.code,
        }),
        ...(response.scipComponentArticle.Characteristics?.DensityUnit && {
          DensityUnit:
            response.scipComponentArticle.Characteristics?.DensityUnit?.code,
        }),
        ...(response.scipComponentArticle.Characteristics?.VolumeUnit && {
          VolumeUnit:
            response.scipComponentArticle.Characteristics?.VolumeUnit?.code,
        }),
        ...(response.scipComponentArticle.Characteristics?.Color && {
          Color: response.scipComponentArticle.Characteristics?.Color?.code,
        }),
      };
      form.setFieldValue("Characteristics", Characteristics);
      props.type !== "preview" &&
        setCurrentStep(
          parseInt(response.scipComponentArticle?.currentStep || 0)
        );
    });
    setDatasetLoading(false);
  };

  const onValuesChange = (value: any) => {
    if (value.version !== undefined) {
      setVersionModified(true);
    }
  };

  const fetchPhrases = (phraseGroup: string) => {
    setDatasetLoading(true);
    getPhrasesByGroup(phraseGroup)
      .then((response) => {
        const data = response.map((e) => e.value);
        const filteredData = data.flat().map((item) => ({
          code: item.code,
          text: item.text,
        }));

        setArticleCategory(
          filteredData?.map((e: any) => ({
            value: e.code,
            ...e,
          }))
        );
      })
      .catch(() => {
        setDatasetLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
      fetchPhrases("PG6-60768");
      setCurrentStep(0);
      handleSearchSubstances();
      // handleSearchArticleCategory(props?.formData?.ArticleCategory);
      setEditScip(props?.type !== "preview" ? true : false);
      props.type !== "supplier" && fetchData();
      setConcernIcon("");
      setIdentifiersIcon("");
      setInstructionIcon("");
      if (props.type === "supplier") {
        fetchRegulationData();
        form.setFieldValue(
          "version",
          props.formData?.compliances?.complianceOutputs?.version
        );
        form.setFieldValue(
          "PrimaryArticleIdentifierValue",
          props.formData?.manufacturer?.itemNumber
        );
        form.setFieldValue(
          "PrimaryArticleIdentifierType",
          PrimaryArticleIdentifierTypeList.find((v: any) => v.code === "66306")
            ?.code
        );
        setScipComponentArticle(props.formData);
      }
    }
  }, [open]);

  useEffect(() => {
    if (scipComponentArticle) {
      fetchRegulationData();
    }
  }, [scipComponentArticle]);

  return (
    <>
      <Button
        type={props.type === "create" ? "primary" : "text"}
        onClick={showDrawer}
        title={props.type !== "preview" ? "Edit Dataset" : "Preview Dataset"}
        icon={props.type !== "preview" ? <EditOutlined /> : <EyeOutlined />}
      ></Button>
      <Drawer
        title={
          props.type !== "preview"
            ? `Edit Dataset- ${props.formData?.manufacturer?.itemNumber}`
            : `Preview Dataset- ${props.formData?.manufacturer?.itemNumber}`
        }
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        maskClosable={false}
        extra={
          <Space>
            {editScip ? (
              <>
                <Button onClick={onClose} data-testid="cancel-scip-button">
                  Cancel
                </Button>
                {currentStep <= 1 && (
                  <Button
                    onClick={onSaveArticle}
                    loading={loading}
                    type="primary"
                  >
                    {primaryArticleIdentifierType !== "66297"
                      ? "Save & Continue"
                      : "Create Dataset"}
                  </Button>
                )}
                {currentStep === 2 && (
                  <Button
                    onClick={onSubmit}
                    type="primary"
                    loading={loading}
                    data-testid="submit-scip-button"
                  >
                    Create Dataset
                  </Button>
                )}
              </>
            ) : (
              <Button type="primary" onClick={onEditClick}>
                Edit
              </Button>
            )}
          </Space>
        }
        loading={regulationLoading}
      >
        {scipComponentArticle?.currentStep === "2" && substancesLoading ? (
          <Spin fullscreen spinning={substancesLoading} />
        ) : (
          <>
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                ...props?.formData,
              }}
              onValuesChange={onValuesChange}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Tabs
                defaultActiveKey="0"
                activeKey={currentStep.toString()}
                onChange={handleTabChange}
                items={items}
              />
            </Form>
          </>
        )}
      </Drawer>
    </>
  );
};

export default ScipForm;
