import React, { useEffect, useState } from "react";
import { FilterOutlined, EyeOutlined, ExportOutlined } from "@ant-design/icons";
import { Space, Typography, Button, Table, Input, Tag, Spin } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";

import { Product } from "../../../../utils/types/product";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  listProducts,
  listProductFilters,
} from "../../../../redux/actions/productsAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../../utils/localStore";

import ProductModal from "../../../../components/forms/ProductForm";
import CreateDeclarationModal from "../../../../components/modals/CreateDeclarationModal";
import CreateBulkDeclarationModal from "../../../../components/modals/CreateBulkDeclarationModal";
import ReadSupportingDocument from "../../../../components/modals/ReadSupportingDocuments";
import ErrorBoundary from "../../../../utils/errorBoundary";
import AddToCampaignListModal from "../../../../components/modals/AddToCampaignListModal";

const { Search } = Input;
const { Title, Text } = Typography;

const ProductList: React.FC<{
  onPreviewClick?: any;
}> = ({ onPreviewClick }) => {
  const dispatch = useAppDispatch();

  const [products, setProducts] = useState<Product[]>([]);
  const [productCount, setProductCount] = useState<number>(0);

  const [search, setSearch] = useState<any>();
  const UserRegulations: any = getRegulationsFromLocalStorage() ?? [];

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const productSpecifications = getLocalStorage("user").productSpecifications;

  const onSearch = (value: any) => {
    setSearch({
      searchField: true,
      query:
        value?.key === "Enter" ? value?.target?.value.trim() : value.trim(),
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Product>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [filterData, setFilterData] = useState<any>({});

  const handlePreviewClick = (record: any) => {
    const values = {
      number: record.number,
      type: "ProductProfile",
      key: record._id,
      name: record.name,
    };
    onPreviewClick(values);
  };

  const fetchData = () => {
    const filters: { [x: string]: FilterValue | null }[] = [];
    Object.keys(tableFilters).forEach((key) => {
      if (tableFilters[key] && tableFilters[key]?.length) {
        filters.push({ [key]: tableFilters[key] });
      }
    });
    setLoading(true);
    dispatch(
      listProducts({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        searchField:
          !filters.length && search?.searchField ? search?.query : "",
        filtersUsed: filters.length ? "useFilters" : false,
        filters: filters.length
          ? JSON.stringify({
              elements: filters,
              path: [],
            })
          : [],
      })
    )
      .then((response) => {
        setProducts(response.products);
        setProductCount(response.productCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchFilterData = (columnName: string) => {
    setLoadingData(true);
    dispatch(listProductFilters({ column: columnName })).then(
      (response: any) => {
        setFilterData((prev: any) => ({ ...prev, [columnName]: response }));
        setLoadingData(false);
      }
    );
  };

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const columns: ColumnsType<Product> = [
    {
      title: "Action",
      key: "operation",
      width: 120,
      render: (_, record) => (
        <Space size={0}>
          {permissions?.update.includes("products") && (
            <ProductModal type="edit" formData={record} fetchData={fetchData} />
          )}
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
            data-testid="preview-product-button"
            title="Preview"
          />
          <CreateDeclarationModal product={record} />
          {/* <Button type="text" icon={<DeleteOutlined />} /> */}
        </Space>
      ),
    },
    {
      title: "Product #",
      dataIndex: "number",
      key: "number",
      sorter: true,
      onFilterDropdownOpenChange: (open) => open && fetchFilterData("number"),
      filters: filterData?.number || [],
      filteredValue: tableFilters.number || null,
      filterSearch: true,
      width: 150,
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      onFilterDropdownOpenChange: (open) => open && fetchFilterData("name"),
      filters: filterData?.name || [],
      filteredValue: tableFilters.name || null,
      filterSearch: true,
      width: 160,
    },
    {
      title: "Revision",
      dataIndex: "revision",
      key: "revision",
      sorter: true,
      onFilterDropdownOpenChange: (open) => open && fetchFilterData("revision"),
      filters: filterData?.revision || [],
      filteredValue: tableFilters.revision || null,
      filterSearch: true,
      width: 110,
    },
    ...UserRegulations.map((e: any) => ({
      title: e.name,
      dataIndex: e.regulationNumber,
      key: e.regulationNumber,
      width: 200,
      render: (text: any, record: any) => {
        const regulation = record.regulations?.find(
          (regulation: any) => regulation.number === e.regulationNumber
        );
        return (
          <Text
            style={{
              color:
                regulation?.completedStatus &&
                parseFloat(regulation?.completedStatus) > 70
                  ? "#7CB305"
                  : "#FAAD14",
            }}
          >
            {regulation
              ? regulation.completedStatus + " %completed"
              : "0.0 % completed"}
          </Text>
        );
      },
    })),
    ...(productSpecifications || [])
      .filter((o: any) => !o.hidden)
      .map((e: any) => ({
        title: e.name.charAt(0).toUpperCase() + e.name.slice(1),
        dataIndex: `specification.${e.value}`,
        key: `specification.${e.value}`,
        width: 140,
        render(_: any, record: any) {
          if (e.dataType === "Fileinput") {
            return (
              <>
                {record?.specification &&
                record?.specification[e.value]?.includes("http") ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(record?.specification[e.value])
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="products/components/document/download"
                    filename={
                      record?.specification && record?.specification[e.value]
                    }
                    foldername="components"
                  />
                )}
              </>
            );
          } else if (e.dataType === "Dateinput") {
            return record?.specification && record?.specification[e.value]
              ? new Date(record?.specification[e.value])?.toLocaleDateString()
              : "";
          } else {
            return record.specification ? record.specification[e.value] : "";
          }
        },
      })),
    ...(permissions?.read.includes("materials")
      ? [
          {
            title: "Material Disclosure",
            dataIndex: "materials",
            key: "materials",
            width: 200,
            render: (text: any, record: any) => {
              return (
                <Text
                  style={{
                    color:
                      record.materials?.length > 0 &&
                      record.materials[0]?.completedStatus &&
                      parseFloat(record.materials[0]?.completedStatus) > 70
                        ? "#7CB305"
                        : "#FAAD14",
                  }}
                >
                  {record.materials?.length > 0
                    ? record.materials[0].completedStatus + " %completed"
                    : "0.0 % completed"}
                </Text>
              );
            },
          },
        ]
      : []),
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      // sorter: true,
      render: (text: string, record: any) => (
        <Text>
          {new Date(text).toLocaleString(undefined, {
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </Text>
      ),
    },
  ];

  const handleTableChange: TableProps<Product>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Product> | SorterResult<Product>[]
  ) => {
    const entries = Object.entries(filters);
    const filteredEntries = entries.filter(([key, value]) => value !== null);
    const cleanFilters = Object.fromEntries(filteredEntries);
    setTablePagination(pagination);
    setTableFilters(cleanFilters);
    setTableSorter(sorter as SorterResult<Product>);

    // if (pagination.pageSize !== tablePagination.pageSize) {
    //   return;
    // }
  };

  const onSelectProducts = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Product[]
  ) => {
    setSelectedProducts(selectedRows);
  };

  const productSelection: TableRowSelection<Product> = {
    selectedRowKeys: selectedProducts.map((e) => e._id),
    onChange: onSelectProducts,
    preserveSelectedRowKeys: true,
  };

  const onChildClick = () => {
    setSelectedProducts([]);
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  return (
    <>
      <ErrorBoundary>
        <Spin fullscreen spinning={loadingData} />
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Products
            </Title>
          </div>
          <Space>
            {selectedProducts.length > 0 && (
              <Tag>Selections {selectedProducts?.length}</Tag>
            )}
            {Object.keys(tableFilters).length > 0 ? (
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setTableFilters({});
                  setSearch({});
                }}
              >
                Reset Filters
              </Button>
            ) : (
              <Search
                placeholder="Search using product name, number and description"
                onSearch={onSearch}
                onPressEnter={onSearch}
                allowClear
                style={{ width: 350 }}
              />
            )}
            <AddToCampaignListModal
              selectedItems={selectedProducts}
              onCancel={() => setSelectedProducts([])}
              type="productAdd"
            />
            {permissions?.create.includes("products") && (
              <ProductModal type="create" fetchData={fetchData} />
            )}
            {selectedProducts.length > 0 && (
              <CreateBulkDeclarationModal
                products={selectedProducts}
                fetchData={onChildClick}
              />
            )}
            {/* <Button type="text" icon={<FilterOutlined />} /> */}
          </Space>
        </Space>
        {permissions?.read.includes("products") && (
          <Table
            loading={loading}
            dataSource={products}
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            columns={columns}
            rowKey="_id"
            pagination={{
              ...tablePagination,
              total: productCount,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
            rowSelection={productSelection}
          />
        )}
      </ErrorBoundary>
    </>
  );
};

export default ProductList;
