import React, { useState, useEffect } from "react";

import {
  ExclamationCircleFilled,
  ExclamationCircleTwoTone,
  ExportOutlined,
  FilePdfOutlined,
  FilterOutlined,
  WarningFilled,
  WarningOutlined,
} from "@ant-design/icons";
import { Space, Typography, Button, Table, Input, Tag, Tooltip } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { PendingResponse } from "../../../utils/types/CampaignManager/campaignManager";
import { useAppDispatch } from "../../../redux/hooks";
import { readCampaignPendingResponses } from "../../../redux/actions/CampaignManager/campaignManagerAction";
import AddComplianceApprove from "../../../components/modals/addApproveComplianceModal";
import ReadSupportingDocument from "../../../components/modals/ReadSupportingDocuments";
import { getRegulationsFromLocalStorage } from "../../../utils/localStore";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Search } = Input;
const { Text } = Typography;

const CampaignManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const [pendingResponse, setPendingResponse] = useState<PendingResponse[]>([]);
  const [pendingResponseCount, setPendingResponseCount] = useState<number>(0);
  const regulations = getRegulationsFromLocalStorage() ?? [];

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<PendingResponse>>(
    {}
  );
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [search, setSearch] = useState<any>();
  const [subscriptionEnable, setSubscriptionEnable] = useState<any>();

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const columns: ColumnsType<PendingResponse> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      hidden: false,
      render: (record: any) => (
        <Space>
          <AddComplianceApprove
            type={"icon"}
            formData={record}
            fetchData={fetchData}
            sendgridCampaignId={record?.sendgridCampaignId}
          />
        </Space>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      width: 150,
      hidden: false,
      render: (manufacturer: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          const itemNumberMismatch =
            manufacturer.itemNumber &&
            !record.supplierComplianceResponse?.supplierPdfReader?.answers?.manufacturerItemNumber?.includes(
              manufacturer.itemNumber
            );

          const nameMismatch =
            manufacturer.name &&
            !record.supplierComplianceResponse?.supplierPdfReader?.answers?.manufacturerName?.includes(
              manufacturer.name
            );

          let tooltipTitle = "";

          if (itemNumberMismatch && nameMismatch) {
            tooltipTitle =
              "Act AI: Exact match not found for both manufacturer item number and supplier name. Please verify.";
          } else if (itemNumberMismatch) {
            tooltipTitle =
              "Act AI:Exact match not found. Check for series/part family/division-level declarations.";
          } else if (nameMismatch) {
            tooltipTitle =
              "Act AI:Supplier name mismatch. Verify if it's a parent/subsidiary company";
          }

          return (
            <Text>
              {manufacturer.itemNumber}
              <br />
              <small>{manufacturer.name}</small>
              {(itemNumberMismatch || nameMismatch) && (
                <Tooltip title={tooltipTitle}>
                  <ExclamationCircleFilled
                    style={{ color: "red", marginLeft: "5px" }}
                  />
                </Tooltip>
              )}
            </Text>
          );
        } else {
          return (
            <Text>
              {manufacturer.itemNumber}
              <br />
              <small>{manufacturer.name}</small>
            </Text>
          );
        }
      },
    },
    {
      title: "Regulation",
      dataIndex: "supplierComplianceResponse.regulationNumber",
      key: "supplierComplianceResponse.regulationNumber",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          return (
            <Text>
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.regulationNumber
                    ?.replaceAll("_", " ")
                    .toUpperCase()
                : ""}
              {(record &&
                record.supplierComplianceResponse?.regulationNumber &&
                record.supplierComplianceResponse?.supplierPdfReader?.answers?.regulationNames
                  ?.toUpperCase()
                  .includes(
                    record.supplierComplianceResponse?.regulationNumber.toUpperCase()
                  )) ||
              record.supplierComplianceResponse?.supplierPdfReader?.answers?.regulationNames
                ?.toUpperCase()
                .includes(
                  record.supplierComplianceResponse?.regulationNumber
                    ?.replaceAll("_", " ")
                    .toUpperCase()
                ) ||
              record.supplierComplianceResponse?.supplierPdfReader?.answers?.regulationNames
                ?.toUpperCase()
                .includes(
                  record.supplierComplianceResponse?.regulationNumber
                    .toUpperCase()
                    ?.replaceAll(/_|EU/g, " ")
                ) ? (
                ""
              ) : (
                <Tooltip title="Act AI:Regulation reference missing. Please validate.">
                  <ExclamationCircleFilled
                    style={{ color: "red", marginLeft: "3px" }}
                  />
                </Tooltip>
              )}
            </Text>
          );
        } else {
          return (
            <Text>
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.regulationNumber
                    ?.replaceAll("_", " ")
                    .toUpperCase()
                : ""}
            </Text>
          );
        }
      },
    },

    {
      title: "Compliant",
      dataIndex: "supplierComplianceResponse.complianceOutputs.compliant",
      key: "supplierComplianceResponse.complianceOutputs.compliant",
      sorter: true,
      width: 180,
      hidden: false,
      render: (text: any, record: any) => {
        let compliant = null;
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          compliant =
            record &&
            record.supplierComplianceResponse?.complianceOutputs?.compliant &&
            record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsCompliant?.toUpperCase() ===
              record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ? (
              ""
            ) : (
              <Tooltip title="Act AI: Status not validated. Review document for compliance confirmation.">
                <ExclamationCircleFilled
                  style={{ color: "red", marginLeft: "3px" }}
                />
              </Tooltip>
            );
        }

        if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "YES"
        ) {
          return (
            <>
              <Tag color="green"> YES </Tag>
              {compliant}
            </>
          );
        } else if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "NO"
        ) {
          return (
            <>
              <Tag color="red"> NO </Tag>
              {compliant}
            </>
          );
        } else if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "YES WITH EXEMPTION"
        ) {
          return (
            <>
              <Tag color="orange"> YES WITH EXEMPTION </Tag>
              {compliant}
            </>
          );
        } else if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "UNKNOWN"
        ) {
          return (
            <>
              <Tag color="default"> UNKNOWN </Tag>
              {compliant}
            </>
          );
        } else {
          return (
            <>
              <Tag color="grey">
                {record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.compliant
                  ? record.supplierComplianceResponse?.complianceOutputs
                      ?.compliant
                  : ""}
              </Tag>
              {compliant}
            </>
          );
        }
      },
    },
    {
      title: "Version",
      dataIndex: "supplierComplianceResponse.complianceOutputs.version",
      key: "supplierComplianceResponse.complianceOutputs.version",
      sorter: true,
      width: 180,
      hidden: false,
      render: (text: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          return (
            <Text>
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs?.version
                : ""}
              {record &&
              record.supplierComplianceResponse?.complianceOutputs?.version &&
              record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsVersion
                ?.replace(/\s/g, "")
                .includes(
                  record.supplierComplianceResponse?.complianceOutputs?.version?.replace(
                    /\s/g,
                    ""
                  )
                ) ? (
                ""
              ) : (
                <Tooltip title="Act AI: Regulation version missing/mismatched or document date unknown. Verify.">
                  <ExclamationCircleFilled
                    style={{ color: "red", marginLeft: "3px" }}
                  />{" "}
                </Tooltip>
              )}
            </Text>
          );
        } else {
          return (
            <Text>
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs?.version
                : ""}
            </Text>
          );
        }
      },
    },
    {
      title: "Exemptions",
      dataIndex: "supplierComplianceResponse.complianceOutputs.exemption",
      key: "supplierComplianceResponse.complianceOutputs.exemption",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          return (
            <Text
              style={{ width: 200 }}
              ellipsis={{
                tooltip:
                  record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                    ", "
                  ),
              }}
            >
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                    ", "
                  )
                : ""}
              {record &&
                record.supplierComplianceResponse?.complianceOutputs?.exemption
                  ?.length > 0 &&
                (typeof record.supplierComplianceResponse?.supplierPdfReader
                  ?.answers?.complianceOutputsExemption === "string" &&
                record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsExemption
                  ?.replace(/\s/g, "")
                  .includes(
                    record.supplierComplianceResponse?.complianceOutputs?.exemption
                      ?.join("")
                      .replace(/\s/g, "")
                  ) ? (
                  ""
                ) : (
                  <Tooltip title="Act AI: Exemption(s) not found. Check document for specified exemptions.">
                    <ExclamationCircleFilled
                      style={{ color: "red", marginLeft: "3px" }}
                    />{" "}
                  </Tooltip>
                ))}
            </Text>
          );
        } else {
          return (
            <Text
              style={{ width: 200 }}
              ellipsis={{
                tooltip:
                  record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                    ", "
                  ),
              }}
            >
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                    ", "
                  )
                : ""}
            </Text>
          );
        }
      },
    },
    {
      title: "Substances",
      dataIndex: "supplierComplianceResponse.complianceOutputs.substance",
      key: "supplierComplianceResponse.complianceOutputs.substance",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          return (
            <Text
              style={{ width: 200 }}
              ellipsis={{
                tooltip:
                  record.supplierComplianceResponse?.complianceOutputs
                    ?.substance,
              }}
            >
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs
                    ?.substance
                : ""}
              {record &&
                record.supplierComplianceResponse?.complianceOutputs?.substance
                  ?.length > 0 &&
                (record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsSubstances
                  ?.replace(/\s/g, "")
                  .includes(
                    record.supplierComplianceResponse?.complianceOutputs?.substance?.replace(
                      /\s/g,
                      ""
                    )
                  ) ? (
                  ""
                ) : (
                  <Tooltip title="Act AI:Substance of Concern not identified. Review document for details.">
                    <ExclamationCircleFilled
                      style={{ color: "red", marginLeft: "3px" }}
                    />{" "}
                  </Tooltip>
                ))}
            </Text>
          );
        } else {
          return (
            <Text
              style={{ width: 200 }}
              ellipsis={{
                tooltip:
                  record.supplierComplianceResponse?.complianceOutputs
                    ?.substance,
              }}
            >
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs
                    ?.substance
                : ""}
            </Text>
          );
        }
      },
    },
    {
      title: "Concentration of PFAS",
      dataIndex:
        "supplierComplianceResponse.complianceOutputs.concentrationOfPfas",
      key: "supplierComplianceResponse.complianceOutputs.concentrationOfPfas",
      sorter: true,
      width: 150,
      hidden: regulations.filter((e: any) =>
        e.configuration.find((v: any) =>
          v.value.includes("complianceOutputs.concentrationOfPfas")
        )
      ).length
        ? false
        : true,
      render: (text: any, record: any) => (
        <Text>
          {record && record.supplierComplianceResponse
            ? record.supplierComplianceResponse?.complianceOutputs
                ?.concentrationOfPfas
            : ""}
        </Text>
      ),
    },
    {
      title: "Concentration Unit",
      dataIndex:
        "supplierComplianceResponse.complianceOutputs.concentrationUnit",
      key: "supplierComplianceResponse.complianceOutputs.concentrationUnit",
      sorter: true,
      width: 150,
      hidden: regulations.filter((e: any) =>
        e.configuration.find((v: any) =>
          v.value.includes("complianceOutputs.concentrationUnit")
        )
      ).length
        ? false
        : true,
      render: (text: any, record: any) => (
        <Text>
          {record && record.supplierComplianceResponse
            ? record.supplierComplianceResponse?.complianceOutputs
                ?.concentrationUnit
            : ""}
        </Text>
      ),
    },
    {
      title: "File",
      dataIndex: "complianceDocument",
      key: "complianceDocument",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: string, record: any) => {
        return record.supplierComplianceResponse?.complianceDocument ? (
          record.supplierComplianceResponse?.complianceDocument?.includes(
            "http"
          ) ? (
            <span>
              <ExportOutlined
                onClick={() =>
                  onClickViewLink(
                    record.supplierComplianceResponse?.complianceDocument
                  )
                }
              />{" "}
              Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="campaignmanager/document/download"
              filename={record.supplierComplianceResponse?.complianceDocument}
              foldername="campaign"
              campaignId={record.sendgridCampaignId}
            />
          )
        ) : null;
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ].filter((e: any) => !e.hidden);

  const onSearch = (value: any) => {
    setSearch({
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      readCampaignPendingResponses({
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: search?.query ? search?.query : "",
      })
    )
      .then((response: any) => {
        setSubscriptionEnable(response?.subscriptionEnable);
        setPendingResponse(response?.pendingResponse);
        setPendingResponseCount(response?.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleTableChange: TableProps<PendingResponse>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PendingResponse> | SorterResult<PendingResponse>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<PendingResponse>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  useEffect(() => {
    fetchData(search);
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1rem",
          }}
        >
          <Space>
            <Search
              placeholder="Search using manufacturer name or item number text"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={pendingResponse}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey={(record: any) => {
            return `${record._id}+${record.sendgridCampaignId}+${record.supplierComplianceResponse.regulationNumber}`;
          }}
          // rowKey="_id+supplierComplianceResponse.regulationNumber"
          pagination={{
            ...tablePagination,
            total: pendingResponseCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default CampaignManager;
