import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  message,
} from "antd";
import { readPublicSmelter } from "../../redux/actions/extendedMineralsActions";
import { DownOutlined } from "@ant-design/icons";

// import { Smelter } from "../../utils/types/smelter";
interface Smelter {
  name: string;
  number: string;
  metal: string;
  lookUp: string;
  sourceOfIdentificationNumber: string;
  risk: string;
  address: {
    country: string;
    city: string;
    state: string;
    streetAddress: string;
    pincode: string;
  };
}

interface ModalComponentProps {
  open: boolean;
  onClose: () => void;
  smelterList: Smelter[];
  onAdd: (data: any) => void;
  type: string;
}

const AddSmelter: React.FC<ModalComponentProps> = ({
  open,
  onClose,
  smelterList,
  onAdd,
  type,
}) => {
  const [smelterData, setSmelterData] = useState<Smelter[]>([]);
  const [form] = Form.useForm();
  const [selectedValue, setselectedValue] = useState<string | undefined>(
    undefined
  );
  const [formData, setFormData] = useState<any>({});
  const [disablelookup, setdisablelookup] = useState<boolean>(false || true);

  const handleSelectChange = (value: any) => {
    setdisablelookup(value === "Create New Smelter");
    const selectedData = smelterData.find((option) => option.number === value);

    let newData;
    if (value === "Create New Smelter") {
      newData = {
        ...selectedData,
        name: "",
        number: "",
        sourceOfIdentificationNumber: "",
      };
    } else {
      newData = {
        ...selectedData,
        sourceOfIdentification: selectedData?.number,
      };
    }
    setFormData(newData);
    form.setFieldsValue(newData);
    setselectedValue(value);
  };

  const AddSmelter = async () => {
    try {
      await form.validateFields();
      if (checkForDuplicate()) {
        message.error("Smelter already exist.");
        return;
      }
      const formData = form.getFieldsValue();
      onAdd(formData);
      setselectedValue(undefined);
      setFormData([]);
      form.resetFields();
      onClose();
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const fetchSmelterData = async () => {
    try {
      const response = await readPublicSmelter({
        sortBy: [],
        sortDesc: [],
        page: 1,
        limit: 10,
        search: selectedValue || "",
        softDelete: false,
        type: type,
      });
      setSmelterData(response.data.smelters);
      setSmelterData((prevArray) => [
        {
          name: "Create New Smelter",
          number: "Create New Smelter",
          metal: "",
          lookUp: "",
          sourceOfIdentificationNumber: "",
          risk: "",
          address: {
            country: "",
            city: "",
            state: "",
            streetAddress: "",
            pincode: "",
          },
        },
        ...prevArray,
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const checkForDuplicate = () => {
    for (const item of smelterList) {
      if (selectedValue === item.number) {
        const isDuplicate = true;
        return isDuplicate;
      }
    }
  };

  const handleCancel = () => {
    setselectedValue(undefined);
    setFormData([]);
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (open) {
      fetchSmelterData();
    }
  }, [open, selectedValue]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      width={800}
      centered
      closable={false}
      footer={[
        <Button key="onCancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="continue" type="primary" onClick={AddSmelter}>
          ADD SMELTER
        </Button>,
      ]}
    >
      <Form form={form}>
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              rules={[
                { required: true, message: "Smelter look up is required" },
                {
                  pattern: /^([a-zA-Z0-9()-/]+\s?)*$/i,
                  message: "Not allowed",
                },
              ]}
            >
              <AutoComplete
                placeholder="Smelter Look Up*"
                value={selectedValue}
                onChange={handleSelectChange}
              >
                {smelterData.map((option: Smelter) => (
                  <Select.Option key={option.number} value={option.number}>
                    {option.name}
                  </Select.Option>
                ))}
              </AutoComplete>
              <DownOutlined
                style={{
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                  color: "#9999",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={["risk"]}
              rules={
                disablelookup
                  ? [
                      {
                        required: true,
                        message: "Risk level for smelters is required",
                      },
                    ]
                  : []
              }
            >
              <Select placeholder="Risk*" disabled={disablelookup === false}>
                <Select.Option value="low">Low</Select.Option>
                <Select.Option value="mid">Mid</Select.Option>
                <Select.Option value="high">High</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              name={["name"]}
              rules={
                disablelookup
                  ? [
                      { required: true, message: "Smelter name is required" },
                      {
                        pattern: /^([a-zA-Z0-9()-/]+\s?)*$/i,
                        message: "Not allowed",
                      },
                    ]
                  : []
              }
            >
              <Input
                placeholder="Smelter Name*"
                disabled={disablelookup === false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={["number"]}
              rules={
                disablelookup
                  ? [
                      { required: true, message: "Smelter number is required" },
                      {
                        pattern: /^([a-zA-Z0-9-/,(.)])+$/i,
                        message:
                          'AlphaNumeric Values with Special Characters like "- / , ( ) ." allowed',
                      },
                    ]
                  : []
              }
            >
              <Input
                placeholder="Smelter Number*"
                disabled={disablelookup === false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name={["sourceOfIdentificationNumber"]}
              rules={
                disablelookup
                  ? [
                      {
                        required: true,
                        message:
                          "Smelter source of identification number is required",
                      },
                      {
                        pattern: /^([a-zA-Z0-9()-/]+\s?)*$/i,
                        message: "Not allowed",
                      },
                    ]
                  : []
              }
            >
              <Input
                placeholder="Source Of Indentification*"
                disabled={disablelookup === false}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={["metal"]}
              rules={
                disablelookup
                  ? [
                      { required: true, message: "Smelter metal is required" },
                      {
                        pattern: /^([a-zA-Z0-9()-/]+\s?)*$/i,
                        message: "Not allowed",
                      },
                    ]
                  : []
              }
            >
              <Input placeholder="Metal*" disabled={disablelookup === false} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={["address", "streetAddress"]}
              rules={
                disablelookup
                  ? [
                      {
                        pattern: /^([a-zA-Z0-9()-/]+\s?)*$/i,
                        message: "Not allowed",
                      },
                    ]
                  : []
              }
            >
              <Input
                placeholder="Street Address"
                disabled={disablelookup === false}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={["address", "city"]}
              rules={
                disablelookup
                  ? [
                      { required: true, message: "Smelter city is required" },
                      {
                        pattern: /^([a-zA-Z0-9()-/]+\s?)*$/i,
                        message: "Not allowed",
                      },
                    ]
                  : []
              }
            >
              <Input placeholder="City*" disabled={disablelookup === false} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name={["address", "pincode"]}
              rules={
                disablelookup
                  ? [
                      {
                        pattern: /^([a-zA-Z0-9()-/]+\s?)*$/i,
                        message: "Not allowed",
                      },
                    ]
                  : []
              }
            >
              <Input placeholder="Pincode" disabled={disablelookup === false} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={["address", "state"]}
              rules={
                disablelookup
                  ? [
                      { required: true, message: "Smelter state is required" },
                      {
                        pattern: /^([a-zA-Z0-9()-/]+\s?)*$/i,
                        message: "Not allowed",
                      },
                    ]
                  : []
              }
            >
              <Input placeholder="State*" disabled={disablelookup === false} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={["address", "country"]}
              rules={
                disablelookup
                  ? [
                      {
                        required: true,
                        message: "Smelter country is required",
                      },
                      {
                        pattern: /^([a-zA-Z0-9()-/]+\s?)*$/i,
                        message: "Not allowed",
                      },
                    ]
                  : []
              }
            >
              <Input
                placeholder="Country*"
                disabled={disablelookup === false}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddSmelter;
