import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { ExportState } from "../../utils/types/export";
import { ProductsState } from "../../utils/types/product";
import { setProducts, setComponents } from "../slices/productsSlice";
import { Dispatch } from "redux";

/**
 * Bulk Operations
 */
export const listProducts = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<ProductsState>((resolve, reject) => {
    privateGetApi("/products", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setProducts(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const listProductFilters = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<ProductsState>((resolve, reject) => {
    privateGetApi("/products/filters", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createProduct = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/products", payload)
      .then((response: any) => {
        if (response && response.status === 201 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readProduct = (id: string, payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/products/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          setComponents(response.data.data.components);
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProductReferenceData = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/products/productreference/ids", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateProduct = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/products/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteProduct = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/products/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//analytics
export const productAnalytics = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/analytics/products/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const bulkProductAnalytics = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/analytics/products/analytics", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createDeclarationCertificate = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/products/declaration", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const listDeclarationRecords = (payload: any) => () => {
  return new Promise<ExportState>((resolve, reject) => {
    privateGetApi("/products/declaration", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const uploadDeclaration = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("filename", payload.filename);
    privatePostApi("/products/document/upload", formData)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
