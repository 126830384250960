import React from "react";
import { Layout, Space, Button } from "antd";
import Icon from "./../../../Icon";
import { useNavigate } from "react-router-dom";
import { clearIndexedDatabase } from "../../../utils/constants/indexedDb";

import styles from "./header.module.css";

const { Header } = Layout;

function getLinearGradient(
  hexPrimaryColor: string,
  hexSecondaryColor: string,
  pathname: string
) {
  const isSuppliersPath = location.pathname.includes("/app/suppliers");
  const isComponentsPath = location.pathname.includes("/app/components");

  let hex = (isSuppliersPath ? hexSecondaryColor : hexPrimaryColor)?.replace(
    /^#/,
    ""
  );
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }
  const num = parseInt(hex, 16);
  const r = (num >> 16) & 255;
  const g = (num >> 8) & 255;
  const b = num & 255;
  if (isSuppliersPath) {
    const fromColor = r + 10 + "," + (g + 10) + "," + (b + 10);
    const color2 = r - 40 + "," + (g - 40) + "," + (b - 40);
    const toColor = r - 70 + "," + (g - 70) + "," + (b - 70);
    return `linear-gradient(90deg, rgba(${fromColor},1) 0%, rgba(${color2}, 1) 40%, rgba(${toColor}, 1) 100%)`;
  } else if (isComponentsPath) {
    const fromColor = r + "," + g + "," + b;
    const color2 = r + 20 + "," + (g + 20) + "," + (b + 20);
    const toColor = r + 50 + "," + (g + 50) + "," + (b + 50);
    return `linear-gradient(90deg, rgba(${fromColor},1) 0%, rgba(${color2}, 1) 40%, rgba(${toColor}, 1) 100%)`;
  } else {
    const fromColor = r + 50 + "," + (g + 50) + "," + (b + 50);
    const color2 = r + 10 + "," + (g + 10) + "," + (b + 10);
    const toColor = r - 50 + "," + (g - 50) + "," + (b - 50);
    return `linear-gradient(90deg, rgba(${fromColor},1) 0%, rgba(${color2}, 1) 40%, rgba(${toColor}, 1) 100%)`;
  }
}

const HeaderLayout: React.FC<{
  primaryColor: string;
  secondaryColor: string;
}> = (props) => {
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.clear();
    navigate("/");
    clearIndexedDatabase();
  };

  const backgroundColor = getLinearGradient(
    props?.primaryColor || "#045B7C",
    props?.secondaryColor || "#f39237",
    location?.pathname
  );

  return (
    <Header className={styles.header} style={{ background: backgroundColor }}>
      <Space>
        <Button
          type="text"
          icon={
            <Icon name="profile-icon" style={{ color: "#fff", fontSize: 20 }} />
          }
          onClick={() => navigate("profile-settings")}
        ></Button>
        <Button
          type="text"
          icon={
            <Icon name="logout-icon" style={{ color: "#fff", fontSize: 20 }} />
          }
          onClick={onLogout}
        ></Button>
      </Space>
    </Header>
  );
};

export default HeaderLayout;
