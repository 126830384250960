const DB_NAME = "iuclidDb";
const STORE_NAME = "phrases";
const VERSION = 1;

let db: IDBDatabase | null = null;

export const createDatabase = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, VERSION);

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, {
          keyPath: "key",
          autoIncrement: true,
        });
      }
    };

    request.onsuccess = (event) => {
      db = (event.target as IDBOpenDBRequest).result;
      resolve();
    };

    request.onerror = (event) => {
      reject(
        `Database error: ${(event.target as IDBOpenDBRequest).error?.message}`
      );
    };
  });
};

export const savePhrasesBatch = async (phrases: any): Promise<void> => {
  if (!db) {
    await createDatabase();
  }

  return new Promise((resolve, reject) => {
    if (!db) return reject("Database not initialized");

    const transaction = db.transaction(STORE_NAME, "readwrite");
    const store = transaction.objectStore(STORE_NAME);

    const request = store.put({ key: phrases.key, value: phrases.value });
    request.onsuccess = () => resolve();
    request.onerror = (event) =>
      reject((event.target as IDBRequest).error?.message);
  });
};

export const getPhrasesByGroup = (phraseGroup: string): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    if (!db) return reject("Database not initialized");

    const transaction = db.transaction(STORE_NAME, "readonly");
    const store = transaction.objectStore(STORE_NAME);

    const request = store.getAll(phraseGroup);
    request.onsuccess = () => {
      const phrases = (request.result as any[]).filter(
        (e) => e.key === phraseGroup
      );
      resolve(phrases);
    };
    request.onerror = (event) =>
      reject((event.target as IDBRequest).error?.message);
  });
};

export const clearIndexedDatabase = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase(DB_NAME);

    request.onsuccess = () => resolve();
    request.onerror = (event) =>
      reject((event.target as IDBRequest).error?.message);
  });
};
